import React from 'react';

const Pager = ({ activePage, pageCount, onSelect }) => {
  const pages = [];
  const display = 10;
  
  const begin = Math.max(1, activePage - display / 2);
  const end = Math.min(pageCount, activePage + display / 2 - 1);
  const beginFinal = Math.max(1, begin - ((activePage + display / 2 - 1) - end));
  const endFinal = Math.min(pageCount, end + (begin - (activePage - display / 2)));

  for (let page = beginFinal; page <= endFinal; page++) {
    pages.push(page);
  }
  
  return (
    <div className="text-center">
      <nav aria-label="Page navigation">
        <ul className="pagination pagination-lg">
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => onSelect(1)} aria-label="First">
              &laquo;
            </button>
          </li>
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => onSelect(activePage - 1)} aria-label="Previous">
              &lsaquo;
            </button>
          </li>
          {pages.map(page => (
            <li key={page} className={`page-item ${page === activePage ? 'active' : ''}`}>
              <button className="page-link" onClick={() => onSelect(page)}>
                {page}
              </button>
            </li>
          ))}
          <li className={`page-item ${activePage === pageCount ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => onSelect(activePage + 1)} aria-label="Next">
              &rsaquo;
            </button>
          </li>
          <li className={`page-item ${activePage === pageCount ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => onSelect(pageCount)} aria-label="Last">
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export { Pager };
