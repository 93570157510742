import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

class QuickSearch extends Component {
  constructor(props) {
    super(props);    
        
    this.state = {
      searching: false,
      selected: false,
      options: [],
      query: this.props.defaultValue || ''
    };
    
    this.addNew = false;
    this.timer = 0;
    this.interval = this.props.interval || 750;

    // Function scope bindings
    this.handleBlur = this.handleBlur.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateOption = this.updateOption.bind(this);    
  }
  
  handleBlur(e) {
    if (this.props.onBlur && (this.addNew || this.state.selected 
      || !this.state.searching)) {
        this.props.onBlur(e);
    }
  }
  
  handleSearch(e) {
    const value = e.target.value;
    const updates = {};
    
    // Exit add new mode when input value is empty
    if (!value.length) {
      this.addNew = false;
      updates.selected = false;
    }
    
    // If adding a new entry, update input value and stop here.
    if (!this.addNew && value.length) {
      updates.searching = true;
    }
    
    // Update input value and enter search mode
    this.setState({ 
      ...this.state, 
      ...updates,
      query: value
    });
  
    // Clear current onSearch timer
    if (this.timer) {
      clearTimeout(this.timer);
    }
    
    // Set new onSearch timer
    if (updates.searching) {
      this.timer = setTimeout(() => {
        this.timer = 0;
        
        if (this.props.onSearch) {
          this.props.onSearch(value, this.updateOption)
            .then(options => {
              this.setState({ ...this.state, options });
            });
        }
      }, this.interval);
    }    
  }
  
  handleSelect(value) {
    const option = this.state.options[value];
    const updates = {};
    let changeValue = '';
    
    updates.selected = value;
        
    // If a custom option was selected, execute the callback and stop
    if (this.props.customOption && value in this.props.customOption) {
      this.props.customOption[ value ]();
      this.setState({ searching: false });
      return;
    }
    
    if (value === "new") {
      this.addNew = true;
      changeValue = this.state.query;
    } else {
      updates.query = this.renderInputValue(option);
      changeValue = option;
    }
    
    // Exit search mode and set input value to selected value
    this.setState({ 
      ...this.state, 
      searching: false, 
      ...updates
    });
    
    // Invoke onChange callback if provided
    if (this.props.onChange) {
      this.props.onChange(changeValue);
    }
  }
  
  renderInputValue(option) {
    if (this.props.renderInputValue) {
      return this.props.renderInputValue(option);
    } else {
      return option.label || option.title || option.toString();
    }
  }
  
  renderOption(option) {
    if (this.props.renderOption) {
      return this.props.renderOption(option);
    } else {
      return option.label || option.title || option.toString();
    }
  }
  
  updateOption(option, index) {
    this.setState({
      options: this.state.options.map((opt, idx) =>
        index === idx ? option : opt
      )
    });
  }
  
  render() {
    const options = this.state.options;
    const searching = this.state.searching;
    
    return (
      <div className="quick-search-container">
        <div className="quick-search-input">
          <Form.Control
            type="text" 
            size={ this.props.size }
            name={ this.props.name }
            value={ this.state.query }
            placeholder={ this.props.placeholder } 
            style={ this.props.style }   
            onBlur={ this.handleBlur }        
            onChange={ this.handleSearch }
            ref={ this.props.focus } />
        </div>
        <ul className={`quick-search-select${(searching && ' searching') || ''}`}>
          { Object.keys(this.props.customOption || { }).map((label, idx) =>
            <li key={ idx } onClick={( ) => this.handleSelect(label) } >
              {label}
            </li>
          )}
          { options.length ? options.map((option, key) => 
            <li key={key} onClick={() => this.handleSelect(key) }>
              {this.renderOption(option)}
            </li>
          ) : ''}
        </ul>
      </div>
    );
  }
}

export { QuickSearch };
