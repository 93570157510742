/**
 * @fileoverview Utility functions for handling conditional schema requirements
 */

/**
 * Evaluates conditional requirements based on the schema's allOf section
 * @param {Object} schema - The complete schema object
 * @param {Object} formData - The current form data
 * @returns {Object} An object with additional required fields based on conditions
 */
export const evaluateConditionalRequirements = (schema, formData) => {
  const additionalRequired = {};

  if (schema.allOf && Array.isArray(schema.allOf)) {
    schema.allOf.forEach(condition => {
      if (condition?.if?.properties && condition?.then?.required) {
        const ifProperty = Object.keys(condition.if.properties)[0];
        const ifValue = condition.if.properties[ifProperty].enum?.[0];
        
        if (formData[ifProperty] === ifValue) {
          condition.then.required.forEach(field => {
            additionalRequired[field] = true;
          });
        }
      }
    });
  }

  return additionalRequired;
};

/**
 * Checks if a field is required based on the schema and conditional requirements
 * @param {string} fieldName - The name of the field
 * @param {Object} schema - The complete schema object
 * @param {Object} formData - The current form data
 * @returns {boolean} True if the field is required, false otherwise
 */
export const isFieldRequired = (fieldName, schema, formData) => {
  const baseRequired = schema.required?.includes(fieldName) || false;
  const conditionalRequired = evaluateConditionalRequirements(schema, formData)[fieldName] || false;
  
  console.log('ReqCheck', fieldName, baseRequired, '/', conditionalRequired);
  
  return baseRequired || conditionalRequired;
};
