import React from 'react';
import { Image } from 'react-bootstrap';
import { FaChevronCircleLeft, FaChevronCircleRight, FaImage, FaPlus, FaRegTrashAlt } from 'react-icons/fa';
import { EditComponent } from './AllThing';
import { Upload } from './Upload';


class ImageCarousel extends EditComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      index: 0
    }
  }
  
  setImage(delta) {
    const { index } = this.state;
    const images = this.props.images;
    
    if (index + delta >= images.length) {
      this.setState({ index: 0 });
    } else if (index + delta < 0) {
      this.setState({ index: images.length - 1 });
    } else {
      this.setState({ index: index + delta });
    }
  }

  render() {
    const { index } = this.state;
    const images = Array.isArray(this.props.images) 
      ? this.props.images : [ this.props.images ];
      
    return (
      <div className="image-carousel container">
        { this.renderModal() }
        <div className="image">
          { !!images.length &&
            <Image fluid 
              src={ images[ index ] } 
              onClick={ e => this.props.show && this.props.show(images) } />
          }
          { !images.length && 
            <FaImage className="placeholder" />
          }        
        </div>
        
        { this.props.add &&
          <span className="add">
            <Upload onUpload={ this.props.add }>
              <FaPlus />
            </Upload>
          </span>
        }
        
        { images.length > 1 &&
          <span className="next">
            <FaChevronCircleRight onClick={ e => this.setImage(1) } />
          </span>
        }

        { images.length > 1 &&
          <span className="prev">
            <FaChevronCircleLeft onClick={ e => this.setImage(-1) } />
          </span>
        }
        
        { this.props.remove &&
          <span className="remove">
            <FaRegTrashAlt onClick={ e => this.props.remove(index) } />
          </span>
        }
      </div>
    );
  }
}

export { ImageCarousel };
