import React from 'react';
import axios from 'axios';
import { RowComponent, getProduct } from './AllThing';
import { Button, OverlayTrigger, Popover, Image, Container, Row, Col, Badge } from 'react-bootstrap';
import { FaDolly, FaRegFile, FaBarcode, FaMagic, FaTruck } from 'react-icons/fa';
import { TrackingGlyph } from './TrackingGlyph';
import { MoreInfo } from './MoreInfo';
import Moment from 'react-moment';

class PurchaseRow extends RowComponent {

  constructor(props) {
    super(props);
    
    this.identifyPurchase = this.identifyPurchase.bind(this);
    
    this.onData(row => {
      row.manifest && row.manifest.map((manifest, idx) => 
        manifest.pid && this.updateProduct(row, manifest.pid, idx)
      );
    });
  }
  
  identifyPurchase(id) {
    const endpoint = 'https://dev.newfanglednetworks.com:8080/identifyPurchase';  
    axios.get(`${ endpoint }?id=${ id }`);
  }

  // Load product data for the given pid and merge it with the manifest entry
  // located at state.row.manifest[idx]
  updateProduct(row, pid, idx) {  
    getProduct(pid)
      .then(snap => {
        const product = snap.val();
        
        // Add a duplicate flag
        if (product.title.match(/^DUPLICATE.*/gi)) {
          product.duplicate = true;
        }
        
        this.setState({ ...this.state, row: { ...row, manifest: 
          row.manifest.map((m, i) => i === idx ? { ...product, ...m } : m)
        }});
      });
  }  
  
  render() {
    const row = this.state.row;
    row.history = row.history || {};
    
    // Issue with eBay import leaves empty notes with double single quotes
    if (row.notes === "''") row.notes = "";
    
    return (
      <tr className="purchase-row">
        <td>
          { this.renderModal() }
          <Moment interval={0} format="M/D/YY">{row.history.purchased}</Moment>
        </td>
        <td>
         <div>
           <div>
             <TrackingGlyph 
              tracking={row.tracking} 
              showModal={this.props.showModal} />
             &nbsp;&nbsp;
             <OverlayTrigger 
              trigger="click" 
              rootClose 
              placement="right" 
              overlay={purchaseHistory(row.history)}>
               <span>{row.status}</span>
             </OverlayTrigger>
           </div>
           <div>
            <small className="text-muted">
              <Moment fromNow utc>
                {row.history[row.status]}
              </Moment>
            </small>
          </div>
         </div>
        </td>
        <td>{ row.source }</td>
        <td>
          <Container fluid>
            <Row>            
            
              <Col sm={ 12 }>
                <div className="thumbnail float-left">
                  <span className="thumbnail-wrapper">
                    <Image fluid
                      src={row.images && row.images[0]} 
                      onClick={() => this.showImages(row.images)} />
                  </span>
                </div>
                
                <div className="float-left">
                  <div>
                    <FaMagic 
                      className="clickable"
                      onClick={ () => this.identifyPurchase(row.key) } />
                  
                    <span>{row.title}</span>
                                        
                  </div>

                  <div>
                    { row.manifest && row.manifest.length && row.manifest.filter(m => m).map((man, idx) =>
                      <div key={ idx } className="align-top">
                        <Badge variant={ man.type === 'order' ? 'warning' : 'info' }>
                          { man.type === 'order' ? 'ORD' : 'RCV' }
                        </Badge>
                        
                        <Badge variant="secondary">{ man.quantity }x</Badge>

                        <Badge variant="secondary">
                          { man.condition && man.condition.toUpperCase() }
                        </Badge>

                        <span className="model">
                          <span>{ man.vendor } <strong>{ man.model }</strong></span>                      
                        </span>

                        { man.type === 'order' &&                  
                          <Badge variant="success" className="badge-success-inverted">
                            ${ man.price } ea
                          </Badge>
                        }
                        
                        { man.type === 'order' && !man.duplicate
                          && !Object.keys(man.listing || { }).length &&                          
                            <Badge variant="danger" className="badge-danger-inverted">
                              No Listings
                            </Badge>          
                        }

                        { man.type === 'receipt' && man.notes && 
                          <MoreInfo header="Receiving Notes" body={ man.notes }>
                            <FaRegFile className="text-info" /> 
                          </MoreInfo>
                        }

                        { man.type === 'receipt' && man.serials && 
                          <MoreInfo header="Serials" body={ man.serials }>
                            <FaBarcode className="text-info" /> 
                          </MoreInfo>
                        }
                        
                        { man.type === 'receipt' && man.return &&
                          <MoreInfo 
                            header="Return Tracking" 
                            body={ man.return }>
                              <FaTruck className="text-info" /> 
                          </MoreInfo>
                        }

                      </div>
                    )}                          
                  </div> 
                </div>
              </Col>
            </Row>
          </Container>        
        </td>
        <td>{ row.from }</td>
        <td>
          <small className={ (row.stitch && 'text-success') || 'text-danger' }>
            <strong>{ (row.stitch && 'POSTED') || 'NOT POSTED' }</strong>
          </small>
        </td>
        <td>
          <Button size="xsmall" onClick={e => this.props.editPurchase(row)}>
           <FaDolly />
          </Button>
        </td>
      </tr>
    );
  }
}

const purchaseHistory = (history) => (
  <Popover id="popover-trigger-click-root-close" title="Purchase History">
    {history.purchased && <div><strong>Purchased:</strong> <Moment format="M/D/YY">{history.purchased}</Moment></div>}
    {history.paid && <div><strong>Paid:</strong> <Moment format="M/D/YY">{history.paid}</Moment></div>}
    {history.cancelled && <div><strong>Cancelled:</strong> <Moment format="M/D/YY">{history.cancelled}</Moment></div>}
    {history.shipped && <div><strong>Shipped:</strong> <Moment format="M/D/YY">{history.shipped}</Moment></div>}
    {history.delivered && <div><strong>Delivered:</strong> <Moment format="M/D/YY">{history.delivered}</Moment></div>}
    {history.received && <div><strong>Received:</strong> <Moment format="M/D/YY">{history.received}</Moment></div>}
    {history.returned && <div><strong>Returned:</strong> {history.returned}</div>}
    {history.refunded && <div><strong>Refunded:</strong> <Moment format="M/D/YY">{history.refunded}</Moment></div>}
  </Popover>
)

export { PurchaseRow };
