import React from 'react';
import { RowComponent, getProduct } from './AllThing';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaBan, FaPencilAlt, FaStar, FaTimes } from 'react-icons/fa';
import Moment from 'react-moment';

class ReceiptRow extends RowComponent {
  constructor(props) {
    super(props);    
    
    this.onData((data) => (data.manifest || []).map((item, idx) =>
      item.pid && this.updateStock(item.pid, idx)
    ));
  }
  
  updateStock(pid, idx) {
    getProduct(pid)
      .then(snapshot => {
        const p = snapshot.val();
        const stock = parseInt(
          p.variant && p.variant.new && p.variant.new.stock
          && p.variant.new.stock['Finished Goods (Emery)']
          && p.variant.new.stock['Finished Goods (Emery)'].available
        , 10) || 0;
        
        this.setState(s => ({ 
          row: { ...s.row, manifest: s.row.manifest.map((item, i) => 
            i === idx ? { ...item, stock, title: p.title } : item
          )}
        }));          
      });
  }
  
  render() {
    const row = this.state.row || {};
    const manifest = row.manifest || [];
    
    const manifestMarkup = manifest.map((item, idx) => {
      const overlay = (
        <Popover 
          title={item.title || item.model} 
          id="manifest-item-popover">
            {item.notes && 
              <span><strong>Notes:</strong> {item.notes}<br /></span>
            }
            {item.serials && 
              <span><strong>Serial Numbers:</strong> {item.serials}<br /></span>
            }
            {item.location && 
              <span><strong>Location:</strong> {item.location}<br /></span>
            }
        </Popover>
      );
    
      return (
        <OverlayTrigger 
          rootClose
          key={idx} 
          trigger="click" 
          placement="bottom"
          overlay={overlay}>
              
            <li 
              className={item.status && item.status.toLowerCase()}>
                <Badge className="quantity" pill>{item.quantity}</Badge>
                <span className="model">{item.model}</span>
                {!item.pid && 
                  <FaStar className="newModel" />
                }
                {!item.stock && item.pid &&
                  <FaBan className="stockStatus" />
                }
            </li>
        </OverlayTrigger>
      );
    });
    
    return (
      <tr className="shipment-row">
        <td><Moment interval={0} format="M/D/YY">{row.received}</Moment></td>
        <td>{row.type}</td>
        <td>{row.from}</td>
        <td className="shipment-manifest">
          <ul>{manifestMarkup}</ul>
        </td>
        <td>{row.tracking}</td>
        <td>{row.reference}</td>
        <td className="text-right edit-controls">
          <FaTimes className="edit" onClick={this.removeRow} />
          <FaPencilAlt className="edit"
            onClick={e => this.props.editShipment(row)} />
        </td>
      </tr>
    );
  }
}

export { ReceiptRow };
