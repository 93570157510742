import React from 'react';
import { ResultsComponent } from './AllThing';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { Pager } from './Pager';
import { ReceiptRow } from './ReceiptRow';
import { ReceiptEdit} from './ReceiptEdit';
import { ModalContent } from './ModalContent';

class Receipts extends ResultsComponent {
  constructor(props) {
    super(props);    
    this.receiveShipment = this.receiveShipment.bind(this);
  }
    
  receiveShipment(row) {
    const receiptType = row ? 'New ' : '';
    const modalContent = (
      <ReceiptEdit 
        row={row} 
        search={this.props.search}
        index={this.props.index}
        hideModal={this.hideModal}
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(`Receive ${receiptType}Shipment`, modalContent);
  }
  
  render() {
    const search = this.props.search;
    const results = this.props.results || [];
    const tableData = results.map(row => 
      <ReceiptRow 
        key={row.key} 
        row={row} 
        editShipment={this.receiveShipment}
        search={search}
        index={this.index} />
    );
    
    return (
      <div>
        <ModalContent 
          show={this.state.showModal} 
          close={this.hideModal} 
          title={this.state.modalTitle} 
          content={this.state.modalContent}
          buttons={this.state.buttons} />
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>
              <Button 
                variant="primary" 
                size="small" 
                onClick={(e) => this.receiveShipment()}>
                  Receive New Shipment
              </Button>
            </Col>
            <Col sm={9} md={10}>
              <Table hover>
                <thead>
                  <tr>
                    <th>Received</th>
                    <th>Type</th>
                    <th>From</th>
                    <th>Manifest</th>
                    <th>Tracking</th>
                    <th>Reference</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
              <Pager
                pageCount={search.pages} 
                activePage={search.page} 
                onSelect={search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Receipts };
