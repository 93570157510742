import React from 'react';
import { EditComponent } from './AllThing';
import {  Button, Container, Row, Col, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';


class ProductTagsEdit extends EditComponent {
  constructor(props) {
    super(props);    
    this.saveFields = [ 'tags' ];
    
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }
  
  addTag() {
    const tags = [ ...(this.state.row.tags || []) ];
    const tag = this.state.row.addTag || '';
  
    if (tag.length && tags.indexOf(tag) < 0) {
      tags.push(tag);

      this.setState({ row: {
        ...this.state.row, tags, addTag: ''
      }});    
    }    
  }
  
  removeTag(tag) {
    const tags = [ ...this.state.row.tags ];
    const tagIndex = tags.indexOf(tag);

    if (tagIndex >= 0) {
      tags.splice(tagIndex, 1);

      this.setState({ row: {
        ...this.state.row, tags
      }});
    }   
  }
  
  render() {
    const row = this.state.row;
    const tags = {};
    
    if (row.tags && row.tags.length) {
      row.tags.map(tag => {
        const tagArr = tag.split(':');
        const group = tagArr[0].trim();
        
        tags[group] = tags[group] || [];
        return tags[group].push((tagArr[1] || '*UNGROUPED*').trim());
      });
    }

    return (
      <Container fluid>
        <Row>
          <Col sm={12}>
            <FormLabel>Tags</FormLabel>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <FormGroup>
              <FormControl                 
                type="text"
                name="addTag" 
                placeholder="Add tag" 
                onChange={this.updateRow} />
            </FormGroup>
          </Col>
          <Col sm={4} className="pl-0">
            <FormGroup>
              <Button 
                size="sm"
                variant="info"
                onClick={this.addTag}>
                  Add Tag
              </Button>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          { Object.keys(tags).length && Object.keys(tags).map(group =>
            <Col sm={3} key={group}>
              <div><strong>{group}:</strong></div>
              {tags[group].map(tag =>
                <div key={tag}>
                  <FaTimes 
                    className="clickable mr-1" 
                    onClick={() => this.removeTag(`${group}: ${tag}`)} />
                  {tag}
                </div>
              )}
            </Col>
          )}
        </Row>
        <br />
      </Container>
    );
  }
}

export { ProductTagsEdit };
