import React from 'react';
import BatchContext from './BatchContext';
import { getChannelIcon, getChannelLink, ResultsComponent } from './AllThing';
import { Badge, Button, Card, Container, ListGroup, Row, Col, Table, Form } from 'react-bootstrap';
import { FaTimes, FaPlus } from 'react-icons/fa';
import { Pager } from './Pager';
import { ProductRow } from './ProductRow';
import { ProductEdit } from './ProductEdit';
import { ProductTagsEdit } from './ProductTagsEdit';
import { ListingEdit } from './ListingEdit';
import { StockCommitEdit } from './StockCommitEdit';

class Products extends ResultsComponent {
  constructor(props) {
    super(props);

    // @TODO clean this up, some of this is no longer relevant
    this.state = {
      ...this.state,
      defaultChannel: 'Amazon US',
      defaultCondition: 'new',
      filters: {
        archived: 'NOT archived:true AND NOT title:DUPLICATE',
        channels: []
      }
    };
    
    this.state.batch.opValue = '';
    this.state.batch.functions = {
      'Add Tag': this.batchAddTag,
      'Remove Tag': this.batchRemoveTag,
      'Set Vendor': this.batchSetVendor,
    };
    
    this.editProduct = this.editProduct.bind(this);
    this.editTags = this.editTags.bind(this);
    this.editListing = this.editListing.bind(this);
    this.editStock = this.editStock.bind(this);
  }
  
  batchAddTag(tag, data, path) {
    data.tags = data.tags || [];
    
    if (data.tags.indexOf(tag) < 0) {
      data.tags.push(tag);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  }
  
  batchRemoveTag(tag, data, path) {
    data.tags = data.tags || [];
    const tagIndex = data.tags.indexOf(tag);

    if (tagIndex >= 0) {
      data.tags.splice(tagIndex, 1);

      return { data: { [`${path}/tags`]: data.tags } }; 
    }
  }
  
  batchSetVendor(vendor, data, path) {
    return {
      data: { [`${path}/vendor`]: vendor }
    };
  }
  
  editProduct(product) {
    const modalContent = (
      <ProductEdit 
        row={ product } 
        search={ this.props.search }
        index={ this.props.index }
        editStock={ this.editStock }
        hideModal={ this.hideModal }
        allowDelete="true"
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(`
      ${product ? 'Edit' : 'Add'} Product (${product ? product.key : 'New'})
    `, modalContent);
  }
  
  editStock(row) { 
    const modalContent = (
      <StockCommitEdit 
        title={ row.title }
        search={ this.props.search }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
        product={ row } />
    );
    
    this.showModal(`Edit Stock`, modalContent);
  }
  
  editTags(row) {
    const modalContent = (
      <ProductTagsEdit 
        row={row} 
        search={this.props.search}
        index={this.props.index}
        hideModal={ this.hideModal }
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(`Edit Product Tags`, modalContent);
  }

  editListing(row, product = {}, listings = []) {
    if (!row || !Object.keys(row).length) {
      row = {
        model: product.model,
        product: product.key
      };
    }
    
    console.log(row);
    console.log(product);
    console.log(listings);
    
    const modalTitle = (
      <span>
        <a href={getChannelLink(row)} target="_blank" rel="noopener noreferrer">
          {getChannelIcon(row.channel)}
        </a>&nbsp;
        {row.key ? 'Edit' : 'Add'} Listing ({row.key || 'New'})
      </span>
    );
    
    const modalContent = (
      <ListingEdit 
        row={row}
        product={product}
        listings={listings}
        search={this.props.search}
        index="listing"
        hideModal={ this.hideModal }
        setModalButtons={this.setModalButtons} />
    );
    
    this.showModal(modalTitle, modalContent);
  }

  render() {
    let tableData = null;
    
    if (this.search.location === window.location.href) {
      tableData = this.state.results.map(row =>
        <ProductRow 
          key={row.key} 
          row={row} 
          search={this.search}
          index={this.index}
          editProduct={this.editProduct}
          editTags={this.editTags}
          editListing={this.editListing}
          editStock={ this.editStock }
          handleCheck={this.handleCheck}
          isChecked={this.isChecked}
          showImages={ this.showImages }
          showModal={this.showModal}
          defaultChannel={this.state.defaultChannel}
          defaultCondition={this.state.defaultCondition} /> 
      );
    } else {
      tableData = <tr><td colSpan="5">Loading Data ...</td></tr>;
    }
    
    return (
      <div>
        { this.renderModal() }
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>

              <Card className={`bulk-edit ${this.hasChecked() ? 'd-block' : 'd-none'}`}>
                <Card.Header><strong>Bulk Editing</strong></Card.Header>
                <Card.Body>
                  <Form.Group className="text-center">
                    <Form.Label className="font-weight-bold">{ this.countChecked() } rows selected</Form.Label>
                    <Form.Check 
                      type="checkbox"
                      onChange={() => this.checkAll(true)} 
                      label={`Select all ${this.search.count} matching rows`} />
                  </Form.Group>                      
                  <Form.Group>
                    <Form.Label>Batch Operation</Form.Label>
                    <Form.Control
                      as="select"
                      name="op"
                      defaultValue={this.state.batch.op}
                      onClick={e => this.handleSelect(e, 'batch')}>
                        <option>Select Operation</option>
                        <option>Add Tag</option>
                        <option>Remove Tag</option>
                        <option>Set Vendor</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="opValue"
                      value={this.state.batch.opValue}
                      onChange={e => this.handleUpdate(e, 'batch')}>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Button 
                      block
                      size="sm"
                      variant="outline-info"
                      onClick={this.addBatchOperation}>
                        Add Bulk Operation
                    </Button>
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <small>
                    Planned Operations: { this.state.batch.operations.length }
                  </small>
                </Card.Footer>

                <ListGroup variant="flush">
                  { this.state.batch.operations.map((operation, idx) =>
                    <ListGroup.Item key={idx} className="d-flex justify-content-between">
                      <span>
                        {idx + 1}. {operation.title} 
                        <Badge size="sm" variant="secondary" className="ml-2">{operation.value}</Badge>
                      </span>
                      <span>
                        <FaTimes 
                          className="clickable" 
                          onClick={() => this.removeBatchOperation(idx)} />
                      </span>
                    </ListGroup.Item>
                  )}
                </ListGroup>

                { !!this.state.batch.operations.length &&
                  <Card.Body>
                    <Form.Group>
                      <BatchContext.Consumer>
                        { batch => (
                          <Button 
                            block
                            size="sm"
                            variant="info"
                            onClick={() => batch.addBatch({
                              dataPaths: this.getBatchDataPaths(),
                              onData: this.state.batch.operations
                            })}>
                              Begin Bulk Updates
                          </Button>
                        )}
                      </BatchContext.Consumer>
                    </Form.Group>
                  </Card.Body>
                }
              </Card>

              <Card>
                <Card.Header><strong>Product Filters</strong></Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Stock Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="fgStock"
                      defaultValue={this.state.filters.fgStock}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Stock</option>
                        <option value="(stock.pri.count:>0 OR stock.new.count:>0 OR stock.likenew.count:>0 OR stock.fba.count:>0 OR stock.src.count:>0)">IN STOCK: New (Any)</option>
                        <option value="stock.fba.count:>0">  -- FBA</option>
                        <option value="stock.pri.count:>0">  -- Prime</option>
                        <option value="stock.new.count:>0">  -- New</option>
                        <option value="stock.likenew.count:>0">  -- Like New</option>
                        <option value="stock.ref.count:>0">  -- Refurbished</option>
                        <option value="stock.src.count:>0">  -- Sourced</option>
                        <option value="stock.use.count:>0">  -- Used</option>
                        <option value="stock.res.count:>0">  -- Reserved</option>
                        <option value="(stock.pri.count:<1 AND stock.fba.count:<1 AND stock.new.count:<1 AND stock.likenew.count:<1)">NO STOCK: New (Any)</option>
                        <option value="stock.fba.count:<1">  -- FBA</option>
                        <option value="stock.pri.count:<1">  -- Prime</option>
                        <option value="stock.new.count:<1">  -- New</option>
                        <option value="stock.likenew.count:<1">  -- Like New</option>
                        <option value="stock.ref.count:<1">  -- Refurbished</option>
                        <option value="stock.src.count:<1">  -- Sourced</option>
                        <option value="stock.use.count:<1">  -- Used</option>
                        <option value="stock.res.count:<1">  -- Reserved</option>
                        <option value="(stock.pri.count:<0 OR stock.new.count:<0 OR stock.likenew.count:<0 OR stock.fba.count:<0 OR stock.ref.count:<0 OR stock.src.count:<0 OR stock.use.count:<0 OR stock.res.count:<0)">
                          NEGATIVE STOCK (Any)
                        </option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Marketplaces</Form.Label>
                    <Form.Control 
                      multiple
                      as="select"
                      name="channel"
                      defaultValue={this.state.filters.channels}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">Any</option>
                        <option value="listing.channel:&quot;Amazon US&quot;">
                          POSTED: Amazon US
                        </option>
                        <option value="listing.channel:&quot;Amazon CA&quot;">
                          POSTED: Amazon CA
                        </option>
                        <option value="listing.channel:&quot;eBay&quot;">
                          POSTED: eBay
                        </option>
                        <option value="listing.channel:&quot;BrokerBin&quot;">
                          POSTED: BrokerBin
                        </option>
                        <option value="listing.channel:&quot;Shopify&quot;">
                          POSTED: Shopify
                        </option>
                        <option value="listing.channel:&quot;NewEgg&quot;">
                          POSTED: NewEgg
                        </option>
                        <option value="listing.channel:&quot;Walmart&quot;">
                          POSTED: Walmart
                        </option>
                        <option value="NOT listing.channel:&quot;Amazon US&quot;">
                          MISSING: Amazon US
                        </option>
                        <option value="NOT listing.channel:&quot;Amazon CA&quot;">
                          MISSING: Amazon CA
                        </option>
                        <option value="NOT listing.channel:&quot;BrokerBin&quot;">
                          MISSING: BrokerBin
                        </option>
                        <option value="NOT listing.channel:&quot;eBay&quot;">
                          MISSING: eBay
                        </option>
                        <option value="NOT listing.channel:&quot;Shopify&quot;">
                          MISSING: Shopify
                        </option>
                        <option value="NOT listing.channel:&quot;NewEgg&quot;">
                          MISSING: NewEgg
                        </option>
                        <option value="NOT listing.channel:&quot;Walmart&quot;">
                          MISSING: Walmart
                        </option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Missing Data</Form.Label>
                    <Form.Control 
                      multiple
                      as="select"
                      name="missing"
                      defaultValue={this.state.filters.missing}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">None</option>
                        <option value="NOT _exists_:model">Model</option>
                        <option value="NOT _exists_:vendor">Vendor</option>
                        <option value="NOT _exists_:upc">UPC</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Product Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="archived"
                      defaultValue={this.state.filters.archived}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Products</option>
                        <option value="NOT archived:true AND NOT title:DUPLICATE">Active Products</option>
                        <option value="archived:true">Archived Products</option>
                        <option value="title:DUPLICATE">Duplicate Products</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>

            </Col>
            <Col sm={9} md={10}>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Form.Check
                        checked={this.isChecked('all')} 
                        type="checkbox" 
                        onChange={() => this.handleCheck('all')} />
                    </th>
                    <th className="product-image">Image</th>
                    <th>Title</th>
                    <th></th>
                    <th><a href="#x" onClick={e => this.sortResults(e, 'analytics.sale.365.sales')}>Annual Sales</a></th>
                    <th><a href="#x" onClick={e => this.sortResults(e, 'lastSaleDate')}>Last Sale</a></th>
                    <th><a href="#x" onClick={e => this.sortResults(e, 'stockOutDate')}>Stock Out</a></th>
                    <th><a href="#x" onClick={e => this.sortResults(e, 'created')}>Created</a></th>
                    <th className="text-right">
                      <Button className="product-add" variant="info" size="sm" title="Add New Product" onClick={e => this.editProduct()}>
                        <FaPlus />
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
              <Pager
                pageCount={this.search.pages} 
                activePage={this.search.page} 
                onSelect={this.search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Products };
