import React, { Component } from 'react';
import firebase from './fire';
import { v4 as uuidv4 } from 'uuid';


class Upload extends Component {
  constructor(props) {
    super(props);    
        
    this.state = {
    };
    
    this.editRef = React.createRef();
    this.storage = firebase.storage();
    this.storageURL = 
      'https://storage.googleapis.com/firebase-newfangled.appspot.com/';

    // Function scope bindings
    this.saveFile = this.saveFile.bind(this);
    this.upload = this.upload.bind(this);
  }
  
  // Autofocus form control when in edit mode
  componentDidUpdate() {
    if (this.state.edit) this.editRef.current.focus();
  }
  
  saveFile(file) {
    const filename = uuidv4();
    const filepath = `listing/image/${ filename }`;
    
    console.log(filepath);
    
    const progress = this.storage.ref()
      .child(filepath)
      .put(file)
      .then(snap => 
        snap.ref
          .getDownloadURL()
          .then(url => this.props.onUpload(url))
      )
      .catch(err => console.log('Upload Error:', err));
  }

  upload(e) {
    const files = e.target.files;
    
    if (!files || !files.length) return;
    
    console.log(files);

    for (let i = 0; i < files.length; i++) {
      this.saveFile(files[ i ]);
    }
  }
      
  render() {
    return (
      <span>
        <input 
          type="file" 
          id="upload" 
          ref={ this.editRef } 
          style={{ display: 'none' }} 
          onChange={ this.upload } />
        <span onClick={ () => this.editRef.current.click() }>
          {this.props.children}
        </span>
      </span>
        
    );
  }
}

export { Upload };
