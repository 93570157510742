import React from 'react';
import { EditComponent } from './AllThing';
import { Container, Row, Col, InputGroup, FormGroup, FormControl, FormLabel, Card, ButtonGroup, Button } from 'react-bootstrap';
import { FaBarcode, FaHome, FaMapMarkerAlt, FaRegFile, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { QuickSearch } from './QuickSearch';
import DatePicker from 'react-datepicker';


class ReceiptEdit extends EditComponent {
  constructor(props) {
    super(props, {
      received: new Date().toJSON(), 
      type: 'Purchase',
    });
    
    this.state.view = 'main';
    
    // Configure data connector to allow new records
    this.allowNewRecord = true;
    
    // Setup collections    
    this.addCollection({
      field: 'manifest',
      defaults: { quantity: 1, status: "0", model: "" },
      include: [],
      exclude: [ 'title', 'stock', 'vendor' ]
    });
    
    // Bindings
    this.handleSearch = this.handleSearch.bind(this);
    this.setManifestView = this.setManifestView.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
  }
  
  componentDidMount() {
    if (!this.props.row) this.addCollectionRow('manifest');
  }
  
  handleSearch(query) {
    return this.props.search
      .simpleQuery('product', `model:${query}* OR title:${query}*`);
  }  
  
  setManifestView(view, idx) {
    this.setState(s => ({
      row: {
        ...s.row,
        manifest: s.row.manifest.map(
          (item, i) => i === idx ? { ...item, view } : item
        )
      }
    }));
  }
  
  updateProduct(product, idx) {
    console.log(product);
  
    const model = { 
      name: 'model', 
      value: product.model || product 
    };
    
    const pid = { 
      name: 'pid', 
      value: (product.length && product.key) || null 
    };
        
    this.updateCollectionRow('manifest', { target: model }, idx);
    this.updateCollectionRow('manifest', { target: pid }, idx);
  }
  
  render() {
    const received = this.state.row.received || new Date().toJSON();  
    const manifest = this.state.row.manifest || [];
    
    const manifestMarkup = manifest.map((row, idx) =>
      <Row key={row.id} className="manifest-item-row">      
        <Col sm={2} className={`quantity ${row.view || 'view1'}`}>
          <FormControl 
            type="text" 
            name="quantity"
            placeholder="Quantity"
            defaultValue={row.quantity}
            onChange={e => this.updateCollectionRow('manifest', e, idx)} />
        </Col>
        <Col sm={4} className={`model ${row.view || 'view1'}`}>
          <QuickSearch 
            name="product"
            placeholder="Search Products"
            allowNew={true}
            defaultValue={row.model && `${row.model} | ${row.title}`}
            renderOption={opt => `${opt.model} | ${opt.title}`}
            onSearch={this.handleSearch}
            onChange={product => this.updateProduct(product, idx)} />
        </Col>
        <Col sm={3} className={`status ${row.view || 'view1'}`}>
          <FormControl 
            as="select" 
            name="status"
            defaultValue={row.status}
            placeholder="Select Status"
            onChange={e => this.updateCollectionRow('manifest', e, idx)} >
              <option disabled hidden value="0">Select Status</option>
              <option>Complete</option>
              <option>Incomplete</option>
              <option>WIP</option>
              <option>Review</option>
          </FormControl>
        </Col>
        <Col sm={9} className={`notes ${row.view || 'view1'}`}>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><FaRegFile /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl 
                type="text" 
                name="notes"
                placeholder="Product Notes"
                defaultValue={row.notes} 
                onChange={e => this.updateCollectionRow('manifest', e, idx)} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={5} className={`serials ${row.view || 'view1'}`}>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><FaBarcode /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl 
                type="text" 
                name="serials"
                placeholder="Product Serial Numbers"
                defaultValue={row.serials} 
                onChange={e => this.updateCollectionRow('manifest', e, idx)} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={4} className={`serials ${row.view || 'view1'}`}>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><FaBarcode /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl 
                type="text" 
                name="upc"
                placeholder="Product UPC"
                defaultValue={row.upc} 
                onChange={e => this.updateCollectionRow('manifest', e, idx)} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={9} className={`location ${row.view || 'view1'}`}>
          <FormGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text><FaMapMarkerAlt /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl 
                type="text" 
                name="location"
                placeholder="Product warehouse location"
                defaultValue={row.location}
                onChange={e => this.updateCollectionRow('manifest', e, idx)} />
            </InputGroup>
          </FormGroup>
        </Col>
        <Col sm={3} className="text-right">
          <ButtonGroup>
            <Button 
              size="small" 
              variant={!row.view || row.view === 'view1' ? 'primary' : 'default'}
              onClick={e => this.setManifestView('view1', idx)}>
                <FaHome />
            </Button>
            <Button 
              size="small" 
              variant={row.view === 'view2' ? 'primary' : 'default'}
              onClick={e => this.setManifestView('view2', idx)}>
                <FaRegFile />
            </Button>
            <Button 
              size="small"
              variant={row.view === 'view3' ? 'primary' : 'default'}
              onClick={e => this.setManifestView('view3', idx)}>
                <FaBarcode />
            </Button>
            <Button 
              size="small"
              variant={row.view === 'view4' ? 'primary' : 'default'}
              onClick={e => this.setManifestView('view4', idx)}>
                <FaMapMarkerAlt />
            </Button>
          </ButtonGroup>
          <Button 
            size="small" 
            variant="danger" 
            onClick={e => this.removeCollectionRow('manifest', idx)}>
              <FaTrashAlt />
          </Button>
        </Col>
      </Row>
    );
  
    return (
      <form>
        <Container fluid>
          { this.props.title &&
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <FormLabel><strong>{this.props.title}</strong></FormLabel>
                </FormGroup>
              </Col>
            </Row>
          }
          <Row>
            <Col sm={3}>
              <FormGroup>
                <FormLabel>Date Received</FormLabel>
                <DatePicker 
                  className="form-control"
                  selected={new Date(received)} 
                  onChange={(value) => this.updateRow({
                    target: { 
                      name: "received", 
                      value: new Date(value).toJSON()
                    }
                  })} />
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <FormLabel>Received From</FormLabel>
                <FormControl                 
                  type="text"
                  name="from" 
                  placeholder="Enter name of shipper" 
                  defaultValue={this.state.row.from}
                  onChange={this.updateRow} />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Tracking Number</FormLabel>
                <FormControl 
                  type="text"
                  name="tracking" 
                  placeholder="Enter shipment tracking number"
                  defaultValue={this.state.row.tracking} 
                  onChange={this.updateRow} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <FormGroup>
                <FormLabel>Shipment Type</FormLabel>
                <FormControl
                  as="select" 
                  name="type"
                  defaultValue={this.state.row.type}
                  onChange={this.updateRow}>
                    <option>Purchase</option>
                    <option>Return</option>
                    <option>Physical</option>
                </FormControl>
              </FormGroup>
            </Col>
            <Col sm={5}>
              <FormGroup>
                <FormLabel>Reference</FormLabel>
                <FormControl                 
                  type="text"
                  name="reference" 
                  placeholder="Enter name of shipper" 
                  defaultValue={this.state.row.reference}
                  onChange={this.updateRow} />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Card>
                <Card.Header>
                  <Row className="justify-content-between align-items-center">
                    <Col>Shipment Manifest</Col>
                    <Col className="text-right">
                      <Button 
                        size="sm" 
                        variant="secondary"
                        onClick={() => this.addCollectionRow('manifest')}>
                          <FaPlus />
                      </Button>                                  
                    </Col>
                  </Row>                                      
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    {manifestMarkup}
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
    </form>
    );
  }
}


export { ReceiptEdit };
