import React, { Component } from 'react';
import NotificationContext from './NotificationContext';


class NotificationProvider extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      notifications: []
    };
    
    this.dismiss = this.dismiss.bind(this);
    this.notify = this.notify.bind(this);
  }

  notify(message, type = "info", headline = "") {
    this.setState({
      notifications: [
        ...this.state.notifications,
        { id: new Date().getTime(), message, type, headline }
      ]
    });
  }
  
  dismiss(alert) {
    const idx = this.state.notifications.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        notifications: [
          ...this.state.notifications.slice(0, idx),
          ...this.state.notifications.slice(idx + 1)
        ]
      });
    }
  }

  render() {
    return (
      <NotificationContext.Provider
        value={{
          dismiss: this.dismiss,
          notify: this.notify,
          list: this.state.notifications
        }}>
          {this.props.children}
      </NotificationContext.Provider>
    );
  }

}

export { NotificationProvider };
