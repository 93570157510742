/**
 * @fileoverview Utility functions for processing Walmart schema
 */

/**
 * Extracts required fields from the Walmart schema
 * @param {Object} schema - The Walmart schema object
 * @returns {Object} An object containing only the required fields
 */
export const extractRequiredFields = (schemaObject) => {
  const requiredFields = {};
  
  if (!schemaObject || typeof schemaObject !== 'object') {
    throw new Error('Invalid schema structure');
  }

  // Add fields that are always required
  if (schemaObject.required) {
    schemaObject.required.forEach(fieldName => {
      if (schemaObject.properties?.[fieldName]) {
        requiredFields[fieldName] = schemaObject.properties[fieldName];
      }
    });
  }

  // Add fields that may be conditionally required
  if (schemaObject.allOf && Array.isArray(schemaObject.allOf)) {
    schemaObject.allOf.forEach(condition => {
      if (condition.then?.required) {
        condition.then.required.forEach(fieldName => {
          if (schemaObject.properties?.[fieldName] && !requiredFields[fieldName]) {
            requiredFields[fieldName] = schemaObject.properties[fieldName];
          }
        });
      }
    });
  }

  return requiredFields;
};

/**
 * Determines the input type based on the schema field type
 * @param {Object} field - The schema field object
 * @returns {string} The corresponding HTML input type
 */
export const getInputType = (field) => {
  if (field.enum || field?.items?.enum) return 'select';
  
  switch (field.type) {
    case 'string':
      return field.format === 'uri' ? 'url' : 'text';
    case 'number':
    case 'integer':
      return 'number';
    case 'boolean':
      return 'checkbox';
    case 'array':
      return 'text'; // For simplicity, we'll use text input for arrays
    case 'object':
      return 'object'; // We'll handle objects separately in the form
    default:
      return 'text';
  }
};

/**
 * Processes a schema field, handling nested structures
 * @param {Object} field - The schema field object
 * @returns {Object} Processed field with flattened structure
 */
export const processSchemaField = (field) => {
  if (field.type === 'object' && field.properties) {
    const processedProperties = {};
    Object.entries(field.properties).forEach(([key, value]) => {
      processedProperties[key] = processSchemaField(value);
    });
    return { ...field, properties: processedProperties };
  }
  
  if (field.type === 'array' && field.items) {
    return { ...field, items: processSchemaField(field.items) };
  }

  return field;
};

/**
 * Converts objects with numbered keys into arrays within a schema and fixes examples for array fields
 * @param {Object} schema - The schema object to parse
 * @returns {Object} The parsed schema with appropriate arrays and fixed examples
 */
export function parseSchemaArrays(schema) {
  if (typeof schema !== 'object' || schema === null) {
    return schema;
  }

  if (Array.isArray(schema)) {
    return schema.map(parseSchemaArrays);
  }

  const keys = Object.keys(schema);
  
  // Check if all keys are numeric strings
  if (keys.every(key => /^\d+$/.test(key))) {
    // Convert to array
    return Object.values(schema).map(parseSchemaArrays);
  }

  // Recursively parse nested objects
  const parsedSchema = {};
  for (const [key, value] of Object.entries(schema)) {
    parsedSchema[key] = parseSchemaArrays(value);
    
    // Remove blank examples.  Schema parser doesn't like them.
    if (parsedSchema[key]?.examples === "") {
      delete parsedSchema[key].examples;
    }    
  }

  return parsedSchema;
}

export function mapListingToWalmartSchema(product, schema) {
  const walmartValues = {
    has_written_warranty: "No",
    netContent: {
      productNetContentUnit: "Each",
      productNetContentMeasure: 1,
    },
    smallPartsWarnings: [ "0 - No warning applicable" ],
    isProp65WarningRequired: "No",
    multipackQuantity: 1,
    countPerPack: 1
  };
  
  Object.keys(product).forEach(key => {
    const val = product[key];
    
    switch (key) {
      case "bullets":
        walmartValues["keyFeatures"] = val;
      break;
      case "channelCondition":
        walmartValues["condition"] = val;
      break;
      case "desc":
        walmartValues["shortDescription"] = val;
      break;
      case "dimensions":
      case "dims":
        walmartValues["assembledProductWidth"] = {
          measure: val.width, 
          unit: "in"
        };
        
        walmartValues["assembledProductHeight"] = {
          measure: val.height, 
          unit: "in"
        };

        walmartValues["assembledProductLength"] = {
          measure: val.depth, 
          unit: "in"
        };

        walmartValues["assembledProductWeight"] = {
          measure: val.weight, 
          unit: "lb"
        };
      break;
      case "image":
        walmartValues["mainImageUrl"] = val[0];
        walmartValues["productSecondaryImageURL"] = val.slice(1);
      break;
      case "title":
        walmartValues["productName"] = val;
      break;
      case "vendor":
        walmartValues["brand"] = val;
      break;
      default:
        if (key in schema?.properties) {
          walmartValues[key] = val;
        }
      break;
    };
  });
  
  return walmartValues;
}

/**
 * Recursively checks if all keys in the data object are present in the schema object.
 * @param {Object} data - The object to be validated.
 * @param {Object} schema - The schema object that defines valid keys.
 * @returns {boolean} - Returns true if all keys in data are present in schema, otherwise false.
 */
function validateKeys(data, schema) {
  // Helper function to recursively check keys
  function checkKeys(dataObj, schemaObj) {
    for (const key in dataObj) {
      // Check if the key exists in the schema
      if (!schemaObj.hasOwnProperty(key)) {
        return false;
      }

      // If the value is an object, recursively check its keys
      if (typeof dataObj[key] === 'object' && dataObj[key] !== null) {
        if (!checkKeys(dataObj[key], schemaObj[key])) {
          return false;
        }
      }
    }
    return true;
  }

  return checkKeys(data, schema);
}
