import React from 'react';
import { EditComponent, TextDisplay } from './AllThing';
import { Alert, Container, Row, Col, Form, FormGroup, FormControl, FormLabel, Image, Spinner } from 'react-bootstrap';
import { FaBarcode, FaBuilding, FaFolderOpen, FaHashtag, FaImages, FaMagic, FaMapMarkedAlt, FaPencilAlt, FaPuzzlePiece, FaTimes, FaTruck, FaRegFile, FaRuler, FaWeight } from 'react-icons/fa';
import { ImageCarousel } from './ImageCarousel';
import { QuickEdit } from './QuickEdit';



class ProductEdit extends EditComponent {
  constructor(props) {
    super(props, { image: [] });    
    this.saveFields = [ 
      'key', 'vendor', 'model', 'upc', 
      'title', 'desc', 'image', 'partNumber',
      'bullets', 'category', 'productURL',
      'dimensions'
    ];
    
    // Configure data connector to allow new records
    this.allowNewRecord = true;    
    
    // Defaults
    this.state.row.upc = this.state.row.upc || "";
    this.state.row.partNumber = this.state.row.partNumber || "";
    this.state.row.category = this.state.row.category || "";
    this.state.row.dimensions = this.state.row.dimensions || {
      width: '', depth: '', height: '', weight: ''
    };
    
    // Bindings
    this.removeImage = this.removeImage.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.updateImages = this.updateImages.bind(this);
    
    // Ensure array on image field
    if (this.state.row.image && !this.state.row.image.length) {
      this.state.row.image = Object.values(this.state.row.image);
    }
  }
  
  beforeSave(row) {
    if (!row.key) {
      row.created = new Date().toJSON();
    }
    
    return Promise.resolve(row);
  }
  
  removeImage(id) {
    this.setState(s => ({ row: {
      ...s.row, 
      image: s.row.image.filter((url, idx) => idx !== id)
    }}));
  }

  updateImage(event) {
    const { value } = event.target;
    
    // Clear input value
    event.target.value = '';
  
    this.setState(s => ({ row: {
      ...s.row, 
      image: [ ...(s.row.image || [ ]), value ]
    }}));
  }
  
  async updateImages(event) {
    const row = this.state.row;
    let imageOptions = row.imageOptions;
    
    if (!imageOptions || !imageOptions.length) {
      imageOptions = await this.generate('images');
    }
  
    this.showImages(imageOptions, {
      selected: row.image,
      add: true, 
      remove: true, next: true, prev: true,
      select: this.selectImage,
      buttons: [
        { value: 'Save', callback: this.selectImages }
      ]
    });
  }
  
  render() {
    const row = this.state.row;    
    const images = row.image || '';
    const bullets = row.bullets || [ ];
    
    return (
      <form autoComplete="off">
        { this.renderModal({ centered: true, dialogClassName: 'modal-sub' }) }
        { row.notes && row.notes.length &&
          <Alert variant="warning">
            <Alert.Heading>Important Product Notes</Alert.Heading>              
            <p>{ row.notes }</p>
          </Alert>
        }
        
      <Container fluid>

          <Row className="product-detail">
            <Col sm={ 6 }>
              { images &&
                <ImageCarousel 
                  images={ images }
                  show={ this.showImages } />
              }
            </Col>
            
            <Col sm={ 6 }>     
                   
              <Form.Group>
                <Form.Label className="product-title">      
                  <QuickEdit as="textarea" size="sm" rows={3} htmlSize={40}
                    name="title"
                    value={ row.title }
                    onChange={ this.updateRow }>
                      <span className="clickable">
                        { row.title || 'Add Title' }
                        <FaPencilAlt />                        
                      </span>
                  </QuickEdit>
                </Form.Label>
              </Form.Group>
              
              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="vendor"
                value={ row.vendor }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaBuilding />
                    <span>{ row.vendor || 'Add Manufacturer' }</span>
                  </span>
              </QuickEdit>
              
              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="model"
                value={ row.model }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaPuzzlePiece />
                    <span>{ row.model || 'Add Model' }</span>
                  </span>
              </QuickEdit>

              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="partNumber"
                value={ row.partNumber }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaHashtag />
                    <span>{ row.partNumber || 'Add Part Number' }</span>
                  </span>
              </QuickEdit>

              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="category"
                value={ row.category }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaFolderOpen />
                    <span>{ row.category || 'Add Category' }</span>
                  </span>
              </QuickEdit>
             
              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="upc"
                value={ row.upc }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaBarcode />
                    <span>{ row.upc || 'Add UPC' }</span>
                  </span>
              </QuickEdit>

              <QuickEdit as="input" size="sm" htmlSize={4}              
                name="dimensions.width"
                value={ row.dimensions.width }
                onChange={ this.updateRow }>
                  <span className="section narrow">
                    <FaRuler />
                    <span>
                      { row.dimensions.width ? 
                        row.dimensions.width + '"' : 'Width (in)' 
                      }
                    </span>
                  </span>
              </QuickEdit>
              
              <span className="section-divider narrow">
                <span>x</span>
              </span>

              <QuickEdit as="input" size="sm" htmlSize={4}              
                name="dimensions.depth"
                value={ row.dimensions.depth }
                onChange={ this.updateRow }>
                  <span className="section narrow">
                    <span>
                      { row.dimensions.depth ? 
                        row.dimensions.depth + '"' : 'Depth (in)' 
                      }
                    </span>
                  </span>
              </QuickEdit>
              
              <span className="section-divider narrow">
                <span>x</span>
              </span>

              <QuickEdit as="input" size="sm" htmlSize={4}              
                name="dimensions.height"
                value={ row.dimensions.height }
                onChange={ this.updateRow }>
                  <span className="section">
                    <span>
                      { row.dimensions.height ? 
                        row.dimensions.height + '"' : 'Height (in)' 
                      }
                    </span>
                  </span>
              </QuickEdit>
              
              <QuickEdit as="input" size="sm" htmlSize={4}              
                name="dimensions.weight"
                value={ row.dimensions.weight }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaWeight />              
                    <span>
                      { row.dimensions.weight ? 
                        row.dimensions.weight + ' lbs' : 'Add Weight (lbs)' 
                      }
                    </span>
                  </span>
              </QuickEdit>                            
              
              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="productURL"
                value={ row.productURL }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaMapMarkedAlt />
                    <span>
                      { row.productURL ? 
                        'Edit Product URL' : 'Add Product URL' 
                      }
                    </span>
                  </span>
              </QuickEdit>

              <span 
                className="section" 
                onClick={ this.updateImages }>
                  { !this.state.generating &&
                    <FaImages />
                  }
                  { !!this.state.generating &&
                    <Spinner 
                      className="icon"
                      animation="border" 
                      variant="secondary" 
                      size="sm" />
                  }
                  <span>Update Images</span>
              </span>
            
              <span 
                className="section" 
                onClick={ () => this.generate('product') }>
                  { !this.state.generating &&
                    <FaMagic />
                  }
                  { !!this.state.generating &&
                    <Spinner 
                      className="icon"
                      animation="border" 
                      variant="secondary" 
                      size="sm" />
                  }
                  <span>Generate Product Details</span>
              </span>
                          
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <div className="mb-3 fw-bold">Description:</div>
              
              <QuickEdit as="textarea" size="sm" rows={3} htmlSize={20}
                name="desc"
                onChange={ this.updateRow }
                value={ row.desc }>
                  <span>
                  <div className="mb-3 clickable">                   
                    { row.desc ? 
                      <TextDisplay text={ row.desc } /> : 
                      'Add Description' 
                    }
                  </div>
                  </span>
              </QuickEdit>
                            
              <div className="mb-3 fw-bold">Bullets:</div>
              <ul>
                { bullets.map((bullet, idx) => 
                
                    <QuickEdit as="input" size="sm" htmlSize={20}
                      key={ idx }
                      name={ `bullets.${ idx }` }
                      value={ bullet }
                      onChange={ this.updateRow }>
                        <li className="clickable">
                          { bullet }
                        </li> 
                    </QuickEdit>
                    
                )}
              </ul>
            </Col>  
          </Row>

        <Row>
          <Col sm={12}>
            <FormGroup>
              <FormLabel>Add Image URL</FormLabel>
              <FormControl                 
                type="text"
                name="imageURL" 
                placeholder="Product image URL" 
                onChange={this.updateImage} />
            </FormGroup>
          </Col>
        </Row>
      </Container>
      
    </form>
      
    );
  }
}

export { ProductEdit };
