import React, { Component } from 'react';
import { Badge, Container, Col, Image, Row } from 'react-bootstrap';
import { FaCheckCircle, FaChevronCircleLeft, FaChevronCircleRight, FaImage, FaPlus, FaRegTrashAlt, FaSearchMinus } from 'react-icons/fa';
import { ModalComponent } from './ModalContent';
import { Upload } from './Upload';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

class Slideshow extends ModalComponent {
  constructor(props) {
    super(props);
    
    const selected = this.props.selected || [ ];
    const images = [ ];
    
    if (this.props.images) {
      if (this.props.images.length) images.push(...this.props.images);
      else images.push(this.props.images);
    }
          
    const allImages = Array.from(new Set([ ...images, ...selected ]));
    
    this.state = { 
      index: 0, 
      selected, 
      images: allImages,
      isZoomed: true  // Set to true by default
    };    
    
    this.addImage = this.addImage.bind(this);
    this.setImage = this.setImage.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.transformRef = React.createRef();

    // If the select callback was provided, call it for each image passed in
    // via the selected prop
    if (typeof this.props.select === 'function') {
      this.state.selected.map(url => 
        this.state.images.indexOf(url) >= 0 && this.props.select(url)
      );
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.images !== this.props.images) {
      this.setState({
        images: Array.isArray(this.props.images) ? 
        Array.from(new Set(this.props.images)) : [ this.props.images ]
      });
    }
  }
  
  addImage(url) {
    if (typeof this.props.add === 'function') {
      this.props.add(url);
    }

    this.setState({ 
      images: [].concat(this.state.images, url) 
    });
  }
  
  removeImage(index) {
    const images = [ ...this.state.images ];
  
    if (typeof this.props.remove === 'function') {
      this.props.remove(index);
    }
    
    this.setState({ 
      images: images.filter((s, idx) => idx !== index) 
    });
  }
  
  selectImage(index) {
    const images = this.state.images;
    const selected = [ ...this.state.selected ];
    const selectedImage = images[ index ];
    
    if (typeof this.props.select === 'function') {
      this.props.select( selectedImage );
    }
  
    if (selected.indexOf(selectedImage) >= 0) {    
      this.setState({ 
        selected: selected.filter(s => s !== selectedImage) 
      });
    } else {
      selected.push(this.state.images[ index ]);
      this.setState({ selected });
    }
  }
  
  setImage(index) {
    this.setState({ index }, () => {
      if (this.transformRef.current) {
        this.transformRef.current.resetTransform();
      }
    });
  }

  advanceImage(delta) {
    const { index, images } = this.state;
    
    if (index + delta >= images.length) {
      this.setState({ index: 0 });
    } else if (index + delta < 0) {
      this.setState({ index: images.length - 1 });
    } else {
      this.setState({ index: index + delta });
    }
  }

  resetZoom() {
    if (this.transformRef.current) {
      this.transformRef.current.resetTransform();
    }
  }
  
  render() {
    const { index, selected, isZoomed } = this.state;
    const images = this.state.images;

    const classSelected = 
      selected.indexOf(images[ index ]) >= 0 ? 'selected' : '';
    
    return (
      <div>

            <div className={ `image-carousel container ${ classSelected }` }>
              { this.renderModal() }
              <div className={ 'embed' in this.props ? 'image' : '' }>
                { !!images.length && !!images[0].length &&
                  <TransformWrapper
                    ref={this.transformRef}
                    defaultScale={1}
                    defaultPositionX={0}
                    defaultPositionY={0}
                    options={{
                      limitToBounds: true,
                      minScale: 1,
                      maxScale: 3,
                    }}
                  >
                    {({ zoomIn, zoomOut, resetTransform, scale }) => (
                      <React.Fragment>
                        <TransformComponent
                          wrapperStyle={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          contentStyle={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={ images[ index ] }
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                            onClick={ e => this.props.show && this.props.show(images) }
                          />
                        </TransformComponent>
                        {scale > 1 && (  // Only show zoom out icon when zoomed in
                          <span className="zoom" onClick={() => this.resetZoom()}>
                            <FaSearchMinus />
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                }
                { (!images.length || !images[0].length) &&
                  <FaImage className="placeholder" />
                }        
              </div>
              
              { this.props.add &&
                <span className="add">
                  <Upload onUpload={ this.addImage }>
                    <FaPlus />
                  </Upload>
                </span>
              }
              
              { this.props.remove &&
                <span className="remove">
                  <FaRegTrashAlt onClick={ e => this.removeImage(index) } />
                </span>
              }

              { this.props.select &&
                <span className={ `select ${ classSelected }` }>
                  <FaCheckCircle 
                    className={ classSelected }
                    onClick={ e => this.selectImage(index) } />
                </span>
              }

              { 'next' in this.props && images.length > 1 &&
                <span className="next">
                  <FaChevronCircleRight onClick={ e => this.advanceImage(1) } />
                </span>
              }

              { 'prev' in this.props && images.length > 1 &&
                <span className="prev">
                  <FaChevronCircleLeft onClick={ e => this.advanceImage(-1) } />
                </span>
              }
              
            </div>
            
            
            
            {images.map((url, idx) => {
              if (!url?.length) return;

              const thumbSelected = 
                selected.indexOf(url) >= 0 ? 'selected' : '';
            
              return (
                <div className="thumb-container" key={ idx }>
                  <div className="thumb-wrapper">
                    <Image
                      src={url} key={idx} 
                      className={ `thumb ${ thumbSelected }` } 
                      onClick={() => this.setImage(idx)} />

                    { this.props.select &&
                      <span className={ `select ${ thumbSelected }` } >
                        <Badge 
                          variant="primary"
                          onClick={() => this.setImage(idx)} >
                            { selected.indexOf(url) + 1 }
                        </Badge>
                      </span>
                    }
                  </div>
                </div>
              );
            })}
      </div>
    );
  }
}

export { Slideshow };
