import React, { Component } from 'react';
import BatchContext from './BatchContext';
import NotificationContext from './NotificationContext';
import fire from './fire';


class BatchProvider extends Component {
  static contextType = NotificationContext;

  constructor(props) {
    super(props);
    
    this.state = {
      batches: []
    };
    
    this.addBatch = this.addBatch.bind(this);
  }

  addBatch(opts) {
    const { 
      dataPaths = [], 
      onData = [],
      onComplete, 
      threads = 5 } = opts;
      
    const batch = {
      dataPaths, onComplete, onData, threads,
      total: dataPaths.length,
      completed: 0,
      complete: false
    };
    
    this.state.batches.push(batch);
    
    for (let i = 0; i < threads; i++) {
      this.processBatch(batch);
    }  
    
  }
  
  processBatch(batch) {
    const { dataPaths, onData } = batch;
  
    if (dataPaths.length) {
      const path = dataPaths.pop();
    
      fire.database()
        .ref(path).once('value')
        .then(snap => {
          const data = snap.val();          
          
          // Iterate through all transformation operations
          return Promise.all(
            onData.map(op => {
             const update = op.callback(op.value, data, path);
             
             // Commit data update, and then wait update.cooldown ms
             // (if specified) before beginning next update
             return update && fire.database().ref()
               .update(update.data)
               .then(() => new Promise((res, rej) => 
                 setTimeout(() => res(), update.cooldown || 0)
               ));
            })
          );
        })
      .then(() => {
        // Increment completed count for progress tracking
        ++batch.completed;
          
        // Process the next item in the dataPath array
        this.processBatch(batch);
      });
    } else if (!batch.complete && batch.completed === batch.total) {
      
      // Mark the batch complete for parallel running threads
      batch.complete = true;
      
      // If onComplete callback was provided, call it
      if (batch.onComplete && typeof batch.onComplete === "function") {
        batch.onComplete(batch);
      }
      
      // Issue notification of batch completion
      this.context.notify(
        `Updated ${batch.completed} records`, 
        'success', 
        'Batch Processing Complete'
      );
      
      // Remove the completed batch
      this.removeBatch(batch);
    }
  }
  
  removeBatch(batch) {
    const { batches } = this.state;
    const idx = batches.indexOf(batches);
    
    if (idx >= 0) {
      this.setState({
        batches: [
         ...batches.slice(0, idx), 
         ...batches.slice(idx + 1) 
        ]        
      });
    }
  }
    
  render() {
    return (
      <BatchContext.Provider
        value={{
          addBatch: this.addBatch
        }}>
          {this.props.children}
      </BatchContext.Provider>
    );
  }

}

export { BatchProvider };
