import React, { Component } from 'react';
import { AlertList } from "react-bs-notifier";
import NotificationContext from './NotificationContext';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Listings } from './Listings';
import { Products } from './Products';
import { Purchases } from './Purchases';
import { Sales } from './Sales';
import { Receipts } from './Receipts';

class ContentContainer extends Component {  
  render() {
    const user = this.props.user;
  
    return (
    <div>
      <NotificationContext.Consumer>
        { notification => !!notification.list.length &&
            <AlertList 
              alerts={notification.list} 
              onDismiss={ alert => notification.dismiss(alert) } />
        }
      </NotificationContext.Consumer>
      <Router>
        <Switch>
          {user && (user.viewOrders || user.admin) &&
            <Route exact path="/" 
              render={ routeProps => 
                <Purchases 
                  results={this.props.results} 
                  search={this.props.search}
                  index="order_purchase_item"
                  sort="history.purchased"
                  {...routeProps} /> 
              } />
          }
            
          {user && (user.viewOrders || user.admin) &&
            <Route exact path="/purchases" 
              render={ routeProps => 
                <Purchases 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="order_purchase_item"
                  sort="history.purchased"
                  {...routeProps} /> 
              } />
          }
            
          {user && (user.viewOrders || user.admin) &&
            <Route exact path="/sales" 
              render={ routeProps => 
                <Sales 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="order_sale"
                  sort="ordered"
                  {...routeProps} /> 
              } />
          }
            
          {user && (user.viewListings || user.admin) &&
            <Route exact path="/listings/:channel" 
              render={ routeProps => 
                <Listings 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="listing"
                  {...routeProps} /> 
              } />
          }
            
          {user && (user.viewProducts || user.admin) &&
            <Route exact path="/products" 
              render={ routeProps => 
                <Products 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="product"
                  {...routeProps} /> 
              } />
          }
            
          {user && (user.viewShipments || user.admin) &&
            <Route exact path="/receipts" 
              render={ routeProps => 
                <Receipts 
                  results={this.props.results} 
                  search={this.props.search} 
                  index="shipment_receipt"
                  sort="received"
                  {...routeProps} /> 
              } />
          }
        </Switch>
      </Router>    
    </div>  
    );
  }
}

export { ContentContainer };
