import React, { useState, useEffect, useRef } from 'react';
import { Form as RJSFForm } from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import { Collapse } from 'react-bootstrap';
import { FaChevronRight, FaChevronDown, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { TbBrandWalmart } from "react-icons/tb";
import { evaluateConditionalRequirements } from './conditionalUtils';
import { mapListingToWalmartSchema } from './schemaUtils.js';



/**
 * Custom object field template for collapsible sections with validation indicators
 * @param {Object} props - Props passed by react-jsonschema-form
 * @returns {JSX.Element} Rendered object field
 */
const CustomObjectFieldTemplate = (props) => {
  return (
    <div>
      {props.properties.map((property, index) => {
        if (property?.content?.props?.uiSchema?.["ui:widget"] === "hidden") {
          return null;
        } else {
          return (
            <CollapsibleField 
              key={index} 
              {...property} 
              errorSchema={props.errorSchema}
            />
          );
        }
      })}
    </div>
  );
};

/**
 * Collapsible field component
 * @param {Object} props - Props for the field
 * @returns {JSX.Element} Rendered collapsible field
 */
const CollapsibleField = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const fieldSchema = props?.content?.props.schema;
  const fieldName = props.name;

  const hasError = props.errorSchema && props.errorSchema[fieldName];
  
  return (
    <div className="mb-3">
      <div onClick={toggleOpen} style={{ cursor: 'pointer' }}>
        <TbBrandWalmart color="#FFD700" />
        {isOpen ? <FaChevronDown /> : <FaChevronRight color="#CCC" />}
        <span>{fieldSchema.title}</span>
        {hasError ? <FaTimesCircle color="red" /> : <FaCheckCircle color="green" />}
      </div>
      <Collapse in={isOpen}>
        <div>
          {props.content}
        </div>
      </Collapse>
    </div>
  );
};


/**
 * WalmartSchemaForm component
 * @param {Object} props - Component props
 * @param {Object} props.schema - The Walmart schema object
 * @returns {JSX.Element} A form generated from the Walmart schema
 */
const WalmartSchemaForm = ({ schema, data, onError, onChange }) => {
  const [formData, setFormData] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  //const [errorSchema, setErrorSchema] = useState({});
  const formRef = useRef();
  
  // Update form data
  useEffect(() => {
    setFormData({
      ...formData,
      ...mapListingToWalmartSchema(data, schema)
    });
  }, [data]);
  
  // Whenever form data changes, call validateForm().  For some reason updating
  // formData isn't triggering the onChange callback from within RJSFForm
  useEffect(() => {
    formRef.current.validateForm();
    onChange(formData);
  }, [formData]);
  
  // Update UI Schema
  useEffect(() => {  
    const updatedUiSchema = 
      Object.keys(schema.properties).reduce((acc, key) => {
        if (schema.required && schema.required.includes(key)) {
          // Show required fields
          acc[key] = {};
        } else {
          // Hide optional fields
          acc[key] = { "ui:widget": "hidden" };
        }
        return acc;
      }, {});
    
    // Define custom templates
    updatedUiSchema["ui:ObjectFieldTemplate"] = CustomObjectFieldTemplate; 
    updatedUiSchema["ui:submitButtonOptions"] = { norender: true };           
    
    setUiSchema(updatedUiSchema);
  }, [schema]);

  /**
   * Handles form data changes
   * @param {Object} param0 - Object containing formData
   * @param {Object} param0.formData - The updated form data
   */
  const handleChange = ({ formData: data }) => {
    setFormData({ formData, ...data });
    onChange(data);
    
    // Evaluate conditional requirements
    const conditionalFields = evaluateConditionalRequirements(schema, data);
    
    // Update UI Schema based on conditional requirements
    setUiSchema(uiSchema => {
      const newUiSchema = { ...uiSchema };
      Object.entries(schema.properties).forEach(([key, value]) => {
        if (schema?.required?.includes(key) || conditionalFields[key]) {
          delete newUiSchema?.[key]?.["ui:widget"];
        } else {
          newUiSchema[key] = { ...newUiSchema[key], "ui:widget": "hidden" };
        }
      });
      
      return newUiSchema;
    });
  };
  
  const handleError = (schemaErrors = []) => {
    const errors = [];
    let hasErrors = false;
    
    schemaErrors.forEach(error => {
      const property = error.property.split('.').filter(prop => prop !== '');
    
      if (schema?.required?.includes(property[0])) {
       errors.push(error);
       hasErrors = true;
      }
    });
    
    onError(errors);
  };

  /**
   * Handles form submission
   * @param {Object} param0 - Object containing formData
   * @param {Object} param0.formData - The form data to be submitted
   */
  const handleSubmit = ({ formData }) => {
    console.log('Form data:', formData);
    // Here you would typically send the data to an API or perform further processing
  };

  return (
    <RJSFForm
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={handleChange}
      onError={handleError}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
      liveValidate={true}
      ref={formRef}
    >
    </RJSFForm>
  );
};

export { WalmartSchemaForm };
