import React from 'react';
import { Card, OverlayTrigger, Popover, Container, Row, Col } from 'react-bootstrap';

const MoreInfo = props => {  
  return (
    <OverlayTrigger 
     rootClose 
     card={ 'card' in props ? true : false }
     trigger={ props.trigger || 'click' } 
     placement={ props.placement || 'right' }
     overlay={ overlay(props) }>
       <span>{ props.children }</span>
    </OverlayTrigger>
  );
}

const overlay = (props) => (
  <Popover>
    { !props.card &&
      <Container>
        <Row>
          <Col>{ props.body }</Col>
        </Row>
      </Container>
    }
    { props.card &&
      <Card>
        { props.header &&
          <Card.Header><strong>{ props.header }</strong></Card.Header>
        }
        { ( props.title || props.body ) &&
          <Card.Body>
            { props.title &&
              <Card.Title>{ props.title }</Card.Title>
            }
            { props.body &&
              <Card.Text>{ props.body }</Card.Text>
            }
          </Card.Body>
        }
      </Card>
    }
  </Popover>
);

export { MoreInfo };
