//import * as firebase from 'firebase'

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

const fbConfig = {
  apiKey: "AIzaSyAtxiHhvBeMWd7Ib6dgacn9mlMySuYwH7c",
  authDomain: "newfangled.firebaseapp.com",
  databaseURL: "https://newfangled.firebaseio.com",
  projectId: "firebase-newfangled",
  storageBucket: "firebase-newfangled.appspot.com",
  messagingSenderId: "52985052365"
};

firebase.initializeApp(fbConfig);

const saveFile = async (file, path = 'uploads') => {
    const filename = uuidv4();
    const filePath = `${ path }/${ filename }`;
    
    console.log(filePath);
    
    const snapshot = await firebase.storage().ref()
      .child(filePath)
      .put(file);
      
    return snapshot.ref.getDownloadURL();
}

export { 
  firebase as default,
  saveFile
};

