import React from 'react';
import { ResultsComponent } from './AllThing';
import { Container, Row, Col, Table, Card, Form } from 'react-bootstrap';
import { Pager } from './Pager';
import { SaleRow } from './SaleRow';
import { ModalContent } from './ModalContent';


class Sales extends ResultsComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state
      //,defaultChannel: 'Amazon US',
      //defaultCondition: 'new',
      //filters: {
      //  archived: 'archived:false',
      //  fgStock: '',
      //  wipStock: '',
      //  channels: []
      //}
    };
    
    //this.editRow = this.editRow.bind(this);
    //this.editListing = this.editListing.bind(this);
  }
  
  //editRow(row) {
  //  const modalContent = (
  //    <ProductEdit 
  //      row={row} 
  //      search={this.props.search}
  //      index={this.props.index}
  //      hideModal={this.hideModal}
  //      setModalButtons={this.setModalButtons} />
  //  );
  //  
  //  this.showModal(`Edit Product (${row.key})`, modalContent);
  //}
  
  //editListing(row, product = {}, listings = []) {
  //  if (!row || !Object.keys(row).length) {
  //    row = {
  //      model: product.model,
  //      product: product.key
  //    };
  //  }
  //  
  //  const modalTitle = (
  //    <span>
  //      <a href={getChannelLink(row)} target="_blank">
  //        {getChannelIcon(row.channel)}
  //      </a>&nbsp;
  //      {row.key ? 'Edit' : 'Add'} Listing ({row.key || 'New'})
  //    </span>
  //  );
  //
  //  const modalContent = (
  //    <ListingEdit 
  //      row={row}
  //      product={product}
  //      listings={listings}
  //      search={this.props.search}
  //      index="listing"
  //      hideModal={this.hideModal}
  //      setModalButtons={this.setModalButtons} />
  //  );
  //  
  //  this.showModal(modalTitle, modalContent);
  //}

  render() {
    let search = this.props.search;
    let tableData = null;
    
    if (search.location === window.location.href) {
      tableData = this.props.results.map(row =>
        <SaleRow 
          key={row.key} 
          row={row} 
          search={this.search}
          index={this.index}
          editRow={this.editRow}
          editListing={this.editListing}
          showModal={this.showModal}
          defaultChannel={this.state.defaultChannel}
          defaultCondition={this.state.defaultCondition} /> 
      );
    } else {
      tableData = <tr><td colSpan="5">Loading Data ...</td></tr>;
    }
    
    return (
      <div>
        <ModalContent 
          show={this.state.showModal} 
          close={this.hideModal} 
          title={this.state.modalTitle} 
          content={this.state.modalContent}
          buttons={this.state.buttons} />
        <Container fluid>
          <Row>
            <Col sm={3} md={2}>
              <Card>
                <Card.Header><strong>Sale Filters</strong></Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Sale Status</Form.Label>
                    <Form.Control 
                      as="select"
                      name="status"
                      defaultValue={this.state.filters.status}
                      onChange={e => this.handleSelect(e, 'filters')}>
                        <option value="">All Sales</option>
                        <option value={ `
                          NOT _exists_:item.product
                          AND NOT channel:"ShipStation" 
                          AND NOT channel:"20140"
                        ` }>NOT LINKED</option>
                    </Form.Control>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={10}>
              <Table>
                <thead>
                  <tr>
                    <th>Ordered</th>
                    <th>Marketplace</th>
                    <th>Ship By</th>
                    <th>Ship Via</th>
                    <th>Deliver In</th>
                    <th>Ship To</th>
                    <th>Order Items</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>{tableData}</tbody>
              </Table>
              <Pager
                pageCount={search.pages} 
                activePage={search.page} 
                onSelect={search.setPage} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export { Sales };
