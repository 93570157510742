import React from 'react';
import { Badge, Col, Image, Row } from 'react-bootstrap';
import { FaImage, FaFolderOpen, FaChevronRight, FaRegTrashAlt } from 'react-icons/fa';
import { getChannelLink } from './AllThing';


const SearchListingResults = ({ 
  channel, title = '', images = [], vendor = '', model = '', categories = [],
  condition = 'NEW', price = {}, showImages = true, id: parentId, onRemove
}) => {
  
  return (
    <Row className="search-product-row">
    { showImages && 
      <Col sm={ 2 }>
        { images.length &&
          <div className="thumbnail">
            <Image 
              fluid 
              src={images[0]?.url} 
              onClick={() => this.showImages(images)} />
          </div>
        }
        { !images && 
          <h1 className="text-center"><FaImage /></h1>
        }
      </Col>
    }
      <Col sm={ showImages ? 10 : 12 }>
        <small>
          <div>
            <span>{vendor} {model}</span>                  
            <span>
              <a 
                href={getChannelLink({ channel, parentId })} 
                target="_blank">{title}
              </a>
            </span>
            <span>
              <FaRegTrashAlt
                className="clickable"
                onClick={ e => onRemove && onRemove(parentId) } />
            </span>
            
          </div>
          <div>
            <Badge variant="secondary">{condition}</Badge>
            <Badge variant="success">{price.amount} {price.currency}</Badge>
            { categories.map((category, idx) =>
              <>
                <span className="text-muted">{category}</span>
                { idx < categories.length - 1 &&
                  <FaChevronRight className="text-muted" />
                }
              </>
            )}
          </div>
        </small>
      </Col>
    </Row>
  );
}

export { SearchListingResults };
