import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Tracking } from './Tracking';
import { FaBan, FaCheck, FaRegClock, FaRoad, FaShare } from 'react-icons/fa';
import Moment from 'react-moment';
import fire from './fire';

class TrackingGlyph extends Component {
  constructor(props) {
    super(props);    
    this.state = { tracking: {} };
    this.showTracking = this.showTracking.bind(this);
  }
  
  componentDidMount() {
    if (this.props.tracking && !this.itemRef) {
      this.itemRef = 
        fire.database().ref('tracking/' + this.props.tracking);

      this.itemRef.on('value', snapshot => {
        this.setState({ tracking: snapshot.val() || {} });
      });
    }
  }
  
  componentWillUnmount() {
    if (this.itemRef) this.itemRef.off();
  }
  
  showTracking() {
    let modalContent = <Tracking tracking={this.state.tracking} />;
    this.props.showModal('Shipment Tracking Details', modalContent);
  }
  
  render() {
    let tracking = this.state.tracking;
    let status = tracking.status || '';
    let statusIcon = null;
    let tooltip = null;
    
    switch (status.toLowerCase()) {
      case 'inforeceived':
      case 'pending':
        statusIcon = <FaRegClock onClick={this.showTracking} />;
        tooltip = <Tooltip id="tooltip">Label Created</Tooltip>
      break;
      case 'intransit':
        statusIcon = <FaRoad onClick={this.showTracking} />;
        tooltip = <Tooltip id="tooltip">Expected <Moment fromNow utc>{tracking.expected}</Moment></Tooltip>;
      break
      case 'outfordelivery':
        statusIcon = <FaShare onClick={this.showTracking} />;
        tooltip = <Tooltip id="tooltip">Out for Delivery</Tooltip>;
      break;
      case 'delivered':
        statusIcon = <FaCheck onClick={this.showTracking} />;
        tooltip = <Tooltip id="tooltip">Delivered <Moment format="M/D/YY" utc>{tracking.delivered}</Moment></Tooltip>;
      break;
      default:
        statusIcon = <FaBan onClick={this.showTracking} />;
        tooltip = <Tooltip id="tooltip">No Tracking</Tooltip>;
      break;
    }
    
    return (
      <OverlayTrigger placement="top" overlay={tooltip}>
        {statusIcon}
      </OverlayTrigger>
    );
  }
}

export { TrackingGlyph };
