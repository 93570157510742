import React from 'react';
import { EditComponent, getProduct, getStockTypes, getStockTypeMap } from './AllThing';
import { Alert, Container, Row, Col, Form, Card, Button, Image, Badge } from 'react-bootstrap';
import { FaBarcode, FaRegFile, FaPlus, FaTrashAlt, FaCheckSquare, FaPencilAlt } from 'react-icons/fa';
import { FaDolly, FaBoxes, FaFileInvoiceDollar, FaLayerGroup, FaImage, FaTruck, FaStore, FaFileInvoice, FaFileDownload } from 'react-icons/fa';
import { StockCommitEdit } from './StockCommitEdit.js';
import { ImageCarousel } from './ImageCarousel';
import { QuickEdit } from './QuickEdit';
import { ProductEdit } from './ProductEdit';
import { SearchProductResults } from './SearchProductResults';


class PurchaseEdit extends EditComponent {
  constructor(props) {
    super(props);
    
    // Configure data connector to allow new records
    this.allowNewRecord = true;

    this.state.row.stitch = this.state.row.stitch || false;
    this.state.row.history = this.state.row.history || { };
    //this.state.row.history.purchased = 
    //  this.state.row.history.purchased || new Date().toJSON();
    
    // Limit fields that are committed to datastore
    // @TODO Remove stitchPosted functionality soon when stitch is gone
    this.saveFields = [ 
      'tracking', 'manifest', 'received', 'receipt', 'id', 'source', 
      'buyerNotes', 'stitch'
    ];    
    
    // Setup collections    
    this.addCollection({
      field: 'manifest',
      defaults: { 
        quantity: 1, 
        lots: 1,
        lotSize: 1,
        price: 0.00,
        type: 'order', 
        condition: 'likenew'
      },
      include: [ 
        'condition', 'lotPrice', 'lotSize', 'lots', 'model', 'notes', 
        'pid', 'price', 'quantity', 'type', 'upc', 'serials', 'title', 'vendor',
        'commit', 'committed', 'return', 'returnReason'
      ],
      exclude: [ 'image', 'listing' ]
    });
    
    // Bindings
    this.addProduct = this.addProduct.bind(this);
    this.commitStock = this.commitStock.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.receivePurchase = this.receivePurchase.bind(this);
    this.toggleStitch = this.toggleStitch.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.updateCommit = this.updateCommit.bind(this);
    this.updateLotPrice = this.updateLotPrice.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
  }
  
  componentDidMount() {
    const row = this.state.row;
    
    if (!this.props.row) this.addCollectionRow('manifest');
    
    // Load product details for existing manifest rows linked to products
    if (row.manifest && row.manifest.length) {
      row.manifest.map((manifest, idx) => 
        manifest.pid && this.updateProduct({ key: manifest.pid }, idx)
      );
    }
  }
  
  // If we are manually adding a purchase entry (newKey is undefined) then
  // insert the purchase AND paid date as today.
  afterSave(row, newKey) {
    const now = new Date().toJSON();
  
    if (newKey) {
      return this.commitAll({
        [ `/order/purchase/item/${ newKey }/history/purchased` ]: now,
        [ `/order/purchase/item/${ newKey }/history/paid` ]: now
      }).then(() => Promise.resolve(row));
    }
    
    return Promise.resolve(row);  
  }
  
  addProduct(idx) {
    const modalContent = (
      <ProductEdit 
        index="product"
        onClose={ row => this.updateProduct(row, idx) }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons } />
    );
    
    this.showModal(`Add Product (New)`, modalContent);
  }
  
  commitStock(row, idx) { 
    const modalContent = (
      <StockCommitEdit 
        title={ row.title }
        search={ this.props.search }
        hideModal={ this.hideModal }
        setModalButtons={ this.setModalButtons }
        cost={ row.price }
        product={ row.pid }
        allocateQuantity={ row.quantity }
        allocateCondition={ row.condition }
        commit={ row.committed && row.commit }
        commitStock={ commit => this.updateCommit(commit, idx) } />
    );
    
    this.showModal(`Allocate Stock`, modalContent);
  }
  
  editProduct(idx) {
    const { pid } = this.state.row.manifest[ idx ];
    
    getProduct(pid)
      .then(snap => {
        const product = { ...snap.val(), key: snap.key };
        
        const modalContent = (
          <ProductEdit 
            row={ product } 
            search={ this.props.search }
            index="product"
            editStock={ this.editStock }
            hideModal={ this.hideModal }
            onClose={ row => this.updateProduct(row, idx) }
            setModalButtons={this.setModalButtons} />
        );
        
        this.showModal(`Edit Product (${pid})`, modalContent);
      });  
  }
  
  receivePurchase(row, idx) {
    this.addCollectionRow('manifest', 
      { ...row, type: 'receipt', upc: null, notes: '' }
    );
  }

  // Exponent notation ^x "boosts" query string search results to be more 
  // relevant.  In this case, we want model matches to show much higher
  // in the results than title matches.
  handleSearch(query, updateOption) {
    return this.props.search
      .simpleQuery('product', 
        `((upc:"${query}")^6
          OR (model:"${query}")^4 
          OR (model:${query}*)^2 
          OR title:${query}*) 
          AND (NOT archived:true)`)
      .then(options => {
        options.map((opt, idx) => 
          getProduct(opt.key)
            .then(snap => updateOption({ ...snap.val(), key: snap.key }, idx)
          )
        );
        
        return options;
      });
  }

  // @TODO This is deprecated.  Remove
  toggleStitch() {
    this.setState({ row: { 
      ...this.state.row, stitch: !this.state.row.stitch 
    }});
  }
  
  toggleType(row, idx) {
    this.updateCollectionRow('manifest', { target: {
      name: 'type',
      value: row.type === 'order' ? 'receipt' : 'order'
    }}, idx);
  }  
    
  updateProduct(product, idx) {
    if (typeof product === 'object') {
      getProduct(product.key)
        .then(snap => {
          const p = snap.val();
          const merge = {
            pid: snap.key,
            title: p.title,
            vendor: p.vendor,
            model: p.model,
            image: p.image,
            listing: p.listing
          };
          
          this.mergeCollectionRow('manifest', merge, idx);          
        });
    } else {
      this.updateCollectionRow('manifest', { 
        target: { name: 'model', value: product }
      }, idx);
    }
  }
  
  updateCommit(stock, idx) {
    const { allocated, note, ...commit } = stock;
  
    this.updateCollectionRow('manifest', { target: { 
      name: 'commit', value: commit 
    }}, idx);

    this.hideModal(1000);
  }
  
  updateLotPrice(e, idx) {
    const { value } = e.target;
    const manifest = this.state.row.manifest[idx];
    
    const lotPrice = { target: { name: 'lotPrice', value }};
    
    const price = { target: {
      name: 'price',
      value: parseFloat(value || 0 / manifest.quantity).toFixed(2)
    }};
    
    this.updateCollectionRow('manifest', lotPrice, idx);
    this.updateCollectionRow('manifest', price, idx);
  }
  
  updateQuantity(e, idx) {
    const { name, value } = e.target;
    const manifest = this.state.row.manifest[idx]; 
    const quantity = { target: { name: 'quantity', value: manifest.quantity } };
    const price = { target: { name: 'price', value: manifest.price } };
    
    switch (name) {
      case 'lots':
        quantity.target.value = 
          parseInt(value || 1) * parseInt(manifest.lotSize || 1);        
      break;
      case 'lotSize':
        quantity.target.value = 
          parseInt(value || 1) * parseInt(manifest.lots || 1);
          
        price.target.value = parseFloat(
          manifest.lotPrice / parseInt(value)
        ).toFixed(2);    
      break;
      default:
        quantity.target.value = 
          parseInt(manifest.lots || 1) * parseInt(manifest.lotSize || 1);
      break;
    }
    
    this.updateCollectionRow('manifest', e, idx);
    this.updateCollectionRow('manifest', quantity, idx);
    this.updateCollectionRow('manifest', price, idx);
  }
  
  render() {
    const manifest = this.state.row.manifest || [];
    
    const stockTypes = getStockTypes();
    
    const conditionOpts = Object.keys(stockTypes).map(key => {
      return { key, value: stockTypes[ key ] };
    });
    
    const manifestMarkup = manifest.map((row, idx) =>
      <Row key={ idx } className="purchase manifest-item-row">
        <Col sm={ 2 }>
          { row.image &&
            <div className="thumbnail">
              <Image fluid 
                src={ row.image && row.image[0] } 
                onClick={ e => this.showImages(row.image) } />
            </div>
          }
          { !row.image && 
            <h1 className="text-center"><FaImage /></h1>
          }
        </Col>
        
        <Col sm={ 10 }>
        
          <div>
          
            <Badge 
              className="clickable"
              variant={ row.type === 'order' ? 'warning' : 'info' }
              onClick={ e => this.toggleType(row, idx) }>
                { row.type === 'order' ? 'ORDERED' : 'RECEIVED' }
            </Badge>
            
            { (row.pid || row.model) &&
              <span>
                <span>
                  <Badge variant="secondary">{ row.quantity }x</Badge>
                </span>
                
                <QuickEdit as="select" size="sm" htmlSize={3}
                  name="condition"
                  options={[ 
                    ...conditionOpts,
                    { key: 'order', value: 'For Orders' },
                    { key: 'parts', value: 'For Parts' },
                    { key: 'return', value: 'Return' }
                  ]}
                  onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                  value={ row.condition }>
                    <Badge variant="secondary" className="clickable">
                      { row.condition.toUpperCase() }
                    </Badge>
                </QuickEdit>
              </span>
            }

            <QuickEdit type="quicksearch" size="sm"
              name="product"
              placeholder="Search Products"
              defaultValue={ row.model && `${ row.model } | ${ row.title }` }
              renderOption={ row => <SearchProductResults row={ row } /> }
              customOption={{ 'Add New': () => this.addProduct(idx) }}
              onSearch={ this.handleSearch }
              onChange={ product => this.updateProduct(product, idx) } >
                <span className="purchase-product">
                  { row.pid &&
                    <span>
                      <span>{ row.vendor } { row.model }</span>                      
                    </span>
                  }
                  { !row.pid && row.model &&
                    <span>{ row.model }</span>
                  }
                  { !row.pid && !row.model && 
                    <em className="text-muted">Search Products</em>
                  }
                </span>
            </QuickEdit>     


            { (row.pid || row.model) && row.type === 'order' &&
              <Badge variant="success" className="badge-success-inverted">
                ${ row.price } ea
              </Badge>
            }

            { (row.pid || row.model) 
              && row.type === 'order'
              && (!row.upc || !row.upc.length) &&
                <Badge variant="danger" className="badge-danger-inverted">
                  No UPC
                </Badge>
            }

            { (row.pid || row.model) 
              && row.type === 'receipt'
              && !Object.keys(row.listing || { }).length &&
                <Badge variant="danger" className="badge-danger-inverted">
                  No Listings
                </Badge>
            }

            <span className="purchase-icons float-right">   
              { row.type === 'order' &&       
                <FaDolly 
                  className="text-primary" 
                  onClick={ e => this.receivePurchase(row, idx) } />
              }
              
              <FaTrashAlt 
                className="text-danger"
                onClick={ e => this.removeCollectionRow('manifest', idx) } /> 
            </span>
            
          </div>
          
          <div>
          
            <QuickEdit as="textarea" size="sm" rows={3} htmlSize={20}
              name="notes"
              onChange={ e => this.updateCollectionRow('manifest', e, idx) }
              value={ row.notes }>
                <span className="purchase-title section">
                <FaRegFile />
                  { row.title && row.title.length &&
                    <small className="text-muted">{row.title}</small>            
                  }
                  { (!row.title || !row.title.length) &&
                    <small className="text-muted"><em>Product Title</em></small>            
                  }
                </span>
            </QuickEdit>   
                   
          </div>
          
          <div>
          
            { row.type === 'order' &&
              <span>
              
                <QuickEdit as="input" size="sm" htmlSize={1} 
                  name="lots"
                  onChange={ e => this.updateQuantity( e, idx) }
                  value={ row.lots }>
                    <span className="purchase-quantity section">                 
                      <FaBoxes />
                      <span>
                        { row.lots }x Lot
                        { row.lots > 1 ? 's' : '' }
                      </span>
                    </span>
                </QuickEdit>

                <QuickEdit as="input" size="sm" htmlSize={1} 
                  name="lotSize"
                  onChange={ e => this.updateQuantity( e, idx) }
                  value={ row.lotSize }>
                    <span className="purchase-lot section">                 
                      <FaLayerGroup />
                      <span>{ row.lotSize }x per Lot</span>
                    </span>
                </QuickEdit>
                
              </span>
            }

            { row.type === 'receipt' &&
              <span>
                <QuickEdit as="input" size="sm" htmlSize={1} 
                  name="quantity"
                  onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                  value={ row.quantity }>
                    <span className="purchase-quantity section">                 
                      <FaBoxes />
                      <span>
                        { row.quantity }x Unit
                        { row.quantity > 1 ? 's' : '' }
                      </span>
                    </span>
                </QuickEdit>
                
                <QuickEdit as="input" size="sm" htmlSize={10} 
                  name="price"
                  onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                  value={ row.price }>
                    <span className="purchase-price section">
                      <FaFileInvoiceDollar />
                      <span>${ row.price } per Unit</span>
                    </span>
                </QuickEdit>
              </span>
            }
            
            { row.type === 'order' &&
              <span>
              
                <QuickEdit as="input" size="sm" htmlSize={10} 
                  name="lotPrice"
                  onChange={ e => this.updateLotPrice(e, idx) }
                  value={ row.lotPrice }>
                    <span className="purchase-price section">
                      <FaFileInvoiceDollar />
                      <span>${ row.lotPrice } per Lot</span>
                    </span>
                </QuickEdit>

                <span 
                  onClick={ () => this.editProduct(idx) }
                  className="purchase-product-edit section clickable">
                    <FaPencilAlt />
                    <span>Edit Product</span>
                </span>
                
              </span>
            }
            
            { row.type === 'receipt' &&
              <QuickEdit as="textarea" size="sm" htmlSize={12} 
                name="serials"
                onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                value={ row.serials }>
                  <span className="purchase-upc section">
                    <FaBarcode />
                    { !row.serials &&
                      <span><em className="text-muted">Add Serials</em></span>
                    }
                    { row.serials && 
                      <span>Edit Serials</span>
                    }
                  </span>
              </QuickEdit>
            }
            
            { row.pid && row.type === 'receipt' 
              && row.condition !== 'order' 
              && row.condition !== 'parts' 
              && row.condition !== 'return' &&
                <span onClick={ () => this.commitStock(row, idx) }>
                  { !row.commit &&    
                    <span className="section">
                      <FaFileDownload />
                      <span>Commit Stock</span>
                    </span>
                  }
                  { row.commit &&
                    <span className="section">
                      <FaCheckSquare />
                      <span>Stock Committed</span>
                    </span>
                  }
                </span>
            }
            
            { row.pid && row.type === 'receipt' && row.condition === 'return' &&
              <span>
              <QuickEdit as="textarea" size="sm" htmlSize={12} 
                name="return"
                onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                value={ row.return }>
                  <span className="section">
                    <FaTruck />
                    { !row.return &&
                      <span>
                        <em className="text-muted">Add Return Tracking</em>
                      </span>
                    }
                    { row.return && 
                      <span>Edit Return Tracking</span>
                    }
                  </span>
              </QuickEdit>

              <QuickEdit as="textarea" size="sm" htmlSize={20} 
                name="returnReason"
                onChange={ e => this.updateCollectionRow('manifest', e, idx) }
                value={ row.returnReason }>
                  <span className="section">
                    <FaTruck />
                    { !row.returnReason &&
                      <span>
                        <em className="text-muted">Add Return Reason</em>
                      </span>
                    }
                    { row.returnReason && 
                      <span>Edit Return Reason</span>
                    }
                  </span>
              </QuickEdit>
              </span>
            }
            
          </div>

        </Col>
        
        {
        }
      </Row>
    );
    
    const row = this.state.row;
    const sellerImages = this.props.row.images;
        
    return (
      <form autoComplete="off">
        { this.renderModal({ centered: true, dialogClassName: 'modal-sub' }) }
        { row.buyerNotes && row.buyerNotes.length &&
          <Alert variant="warning">
            <Alert.Heading>Important Purchase Notes</Alert.Heading>              
            <p>{ row.buyerNotes }</p>
          </Alert>
        }
        
        <Container fluid>
          <Row className="purchase-detail">
            <Col sm={ 6 }>
              { sellerImages &&
                <ImageCarousel 
                  images={ sellerImages }
                  show={ this.showImages } />
              }
            </Col>
            
            <Col sm={ 6 }>            
              <Form.Group>
                <Form.Label>      
                  <div>
                    { row.source === 'eBay' &&
                      <strong>{ this.props.title } *{ row.condition }*</strong>
                    }
                    { row.source === 'PO' &&
                      <strong>Purchase Order # { row.id || '' }</strong>
                    }
                  </div>
                </Form.Label>
                { row.sellerNotes &&
                  <Form.Label>      
                    <div><small>{ row.sellerNotes }</small></div>
                  </Form.Label>
                }
              </Form.Group>

              <QuickEdit as="select" size="sm" htmlSize={10}
                name="source"
                options={[ 
                  { key: 'eBay', value: 'eBay' }, 
                  { key: 'Amazon', value: 'Amazon' }, 
                  { key: 'PO', value: 'PO' }, 
                  { key: 'Return', value: 'Return' },
                ]}
                onChange={ this.updateRow }
                value={ row.source }>
                  <span className="section">
                    <FaStore />
                    <span>{ row.source || 'Add Source' }</span>
                  </span>
              </QuickEdit>
                          
              <QuickEdit as="input" size="sm" htmlSize={10}              
                name="id"
                value={ row.id }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaFileInvoice />
                    { row.id &&
                      <span>Order # { row.id }</span>
                    }
                    { !row.id &&
                      <span>Add Order #</span>
                    }
                  </span>
              </QuickEdit>

              <QuickEdit as="input" size="sm" htmlSize={20}              
                name="tracking"
                value={ row.tracking }
                onChange={ this.updateRow }>
                  <span className="section">
                    <FaTruck />
                    <span>{ row.tracking || 'Add Tracking' }</span>
                  </span>
              </QuickEdit>

              <QuickEdit as="textarea" size="sm" rows={3} htmlSize={20}
                name="buyerNotes"
                onChange={ this.updateRow }
                value={ row.buyerNotes }>
                  <span className="section">                  
                    <FaRegFile />
                    <span>{ row.buyerNotes ? 'Edit Notes' : 'Add Notes' }</span>
                  </span>
              </QuickEdit>
              
              <span className="section">
                <span 
                  onClick={ this.toggleStitch } 
                  className={ (row.stitch && 'text-success') || 'text-danger' }>
                    { row.stitch && "Posted" }
                    { !row.stitch && "Not Posted" }
                </span>
              </span> 
              
              { row.notes &&
                <div className="seller-notes">
                  <span>
                    <small><strong>Condition Notes:</strong> { row.notes }</small>
                  </span>
                </div> 
              }
              
              { row.description &&
                <div className="seller-notes">
                  <span>
                    <small>
                      <strong>Seller Description:</strong> { row.description }
                    </small>
                  </span>
                </div>
              }

            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <Card>
                <Card.Header>
                  <Row className="justify-content-between align-items-center">
                    <Col>Manifest</Col>
                    <Col className="text-right">
                      <Button 
                        size="sm" 
                        variant="secondary"
                        onClick={ () => this.addCollectionRow('manifest') }>
                          <FaPlus />
                      </Button>                                  
                    </Col>
                  </Row>                                      
                </Card.Header>
                <Card.Body>
                  <Container fluid>
                    { manifestMarkup }
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
    </form>
    );
  }
}


export { PurchaseEdit };
