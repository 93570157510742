import React, { Component } from 'react';
import { FormGroup, FormControl, Button } from 'react-bootstrap';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    };    
    
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);  
    this.handleKeyPress = this.handleKeyPress.bind(this);  
  }
  
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  
  handleClick() {
    this.props.auth()
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .catch(err => console.log(err));
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') this.handleClick();
  }      

  render() {
    
    return (
      <div className="login-form">
        <div className="form">
          <h2 className="text-center">Log in</h2>       
          <FormGroup>
            <FormControl
              type="text"
              name="username"
              placeholder="Username"
              defaultValue={this.state.username}
              onChange={this.handleChange} />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="password"
              name="password"
              placeholder="Password"
              defaultValue={this.state.password}
              onChange={this.handleChange}
              onKeyPress={ this.handleKeyPress } />
          </FormGroup>
          <FormGroup className="text-right">
            <Button 
              size="small" 
              variant="primary"
              onClick={this.handleClick}>
                Log in
            </Button>
          </FormGroup>
        </div>
      </div>
    );
  }
}

export { Login };
