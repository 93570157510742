import React from 'react';
import { FaLink } from 'react-icons/fa';
import { RowComponent, getChannelIcon, linkSaleItem } from './AllThing';
import Moment from 'react-moment';

class SaleRow extends RowComponent {
  
  render() {
    let row = this.state.row;
    row.history = row.history || {};
    row.shipping = row.shipping || {};
    row.ship = row.ship || {};
    
    return (
      <tr>
        <td><Moment interval={0} format="M/D/YY">{row.ordered}</Moment></td>
        <td>{getChannelIcon(row.channel)} {row.number} / {row.sourceId} [{row.key}]</td>
        <td><Moment interval={0} format="M/D/YY">{row.shipping.cutoff}</Moment></td>
        <td>{row.shipping.service}</td>
        <td><Moment interval={0} fromNow>{row.shipping.deliver}</Moment></td>
        <td>{row.ship.person}</td>
        <td>
          {Object.keys(row.item || {}).map(id => 
            <div key={id}>
              [<strong>{row.item[id].quantity}</strong>]&nbsp;
              {row.item[id].model || row.item[id].title}&nbsp;
              <FaLink onClick={ () => linkSaleItem(row.key, id) } />
            </div> 
          )}
        </td>
        <td>{row.status}</td>
        <td>{row.tags}</td>
      </tr>
    );
  }
}

export { SaleRow };
