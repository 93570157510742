import React, { Component } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalProps: { }
    };

    this.hideModal = this.hideModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.setModalButtons = this.setModalButtons.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  hideModal() {
    this.setState({ showModal: false });
  }
  
  renderModal(opts) {
    return (
      <ModalContent 
        show={this.state.showModal} 
        close={this.hideModal} 
        title={this.state.modalTitle} 
        content={this.state.modalContent}
        buttons={this.state.buttons} 
        { ...opts } />
    );
  }
  
  setModalButtons(buttons) {
    this.setState({ buttons });
  }  

  showModal(title, content) {
    this.setState({ 
      modalTitle: title, 
      modalContent: content, 
      showModal: true
    });
  }    
}

const ModalContent = props => {
  const buttons = props.buttons || [];

  const buttonsRight = buttons
    .filter(button => button.variant !== 'danger')
    .map((button, idx) => (
      <Button 
        key={idx} 
        variant={ button.variant || 'primary' } 
        disabled={button.disabled}
        onClick={button.callback}>
          {button.value}
      </Button>  
    ));
    
  const buttonsLeft = buttons
    .filter(button => button.variant === 'danger')
    .map((button, idx) => (
      <Button 
        key={idx} 
        variant="danger"
        disabled={button.disabled}
        onClick={button.callback}>
          {button.value}
      </Button>  
    ));
  
  return (
    <Modal 
      size="lg" 
      centered={ props.centered }
      dialogClassName={ props.dialogClassName }
      show={props.show} 
      onHide={props.close}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.content}</Modal.Body>
        <Modal.Footer>
          <div className="w-100">
            <div className="float-left">
              {buttonsLeft}
            </div>
            <div className="float-right">
              {buttonsRight}
              <Button onClick={props.close}>Close</Button>
            </div>
          </div>
        </Modal.Footer>
    </Modal>
  );
}

export { ModalComponent, ModalContent };
