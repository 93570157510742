import React from 'react';
import { AllThingComponent, formatProperCase, TextDisplay } from './AllThing';
import { Row, Col, Container } from 'react-bootstrap';
import { QuickEdit } from './QuickEdit';
import { ModalComponent } from './ModalContent';

class GenerativePicker extends ModalComponent {
  constructor(props) {
    super(props);

    this.state = {
      existingData: props.existingData,
      generativeData: props.generativeData,
    };
  }
  
  useGenerative(element) {
    const { [element]: elementVal, ...generativeData } 
      = this.state.generativeData;
  
    this.props.onUpdate(element, elementVal);
    
    this.setState({ generativeData });
  }
  
  render() {
    const { existingData, generativeData } = this.state;
    const { includeOnly } = this.props;

    // Create a unique array of data element keys between the two data sets
    const elements = includeOnly || Array.from(
      new Set([ 
        ...Object.keys(existingData), 
        ...Object.keys(generativeData) 
      ])
    );
  
    
    return (
      <form autoComplete="off">
        { this.renderModal({ centered: true, dialogClassName: 'modal-sub' }) }
        
      <Container fluid>
        <Row>
          <Col sm={6} className={`
            d-flex justify-content-center align-items-center
            fw-bold mb-3 
          `}>
            Existing Data
          </Col>
          <Col sm={6} className={`
            d-flex justify-content-center align-items-center
            fw-bold mb-3 
          `}>
            Generative Options
          </Col>
        </Row>
      
        { elements.map((element, idx) => {
          if ((existingData[ element ] || generativeData[ element ]) 
              && existingData[ element ] !== generativeData[ element ]) {
                return (
                  <Row key={ idx }>
                    <Col sm={ 6 }>
                      { element in existingData &&
                        <span>
                          <div 
                            className={`
                              d-flex justify-content-center align-items-center
                              fw-bold bg-secondary mb-3 mt-3
                              text-white
                            `}>
                              { formatProperCase(element) }
                          </div>
                          <div>{ formatData(existingData[ element ]) }</div>
                        </span>
                      }
                    </Col>
                    <Col sm={ 6 }>
                      { element in generativeData &&
                        <span>
                          <div 
                            className={`
                              d-flex justify-content-center align-items-center
                              fw-bold bg-primary mb-3 mt-3 clickable
                              text-white
                            `}
                            onClick={ () => this.useGenerative(element) }>
                              { formatProperCase(element) }
                          </div>
                          <div>{ formatData(generativeData[ element ]) }</div>
                        </span>
                      }
                    </Col>
                  </Row>
                );
          }
        })}
      </Container>
      
    </form>
      
    );
  }
}

const formatData = data => {
  let formatted = data;
  
  if (!data) return data;
  
  console.log(data);

  switch (typeof data) {
    case 'string':
    break;
    case 'object':
      if (Array.isArray(data)) {
        formatted = (
          <ul>
            { data.map((e, idx) => 
              <li key={ idx }>{ formatData(e) }</li>
            )}
          </ul>
        );
      } else {
        formatted = (
          <ul>
            { Object.keys(data).map((e, idx) => 
              <li key={ idx }>
                <span className="fw-bold">{ e }:&nbsp;</span>
                <span>{ formatData(data[ e ]) }</span>
              </li>
            )}
          </ul>
        );
      }
    break;
  }
  
  return formatted;
}

export { GenerativePicker };
