import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

class NavBarTop extends Component {
  constructor(props) {
    super(props);  
    
    this.state = { query: '' };  

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.isActive = this.isActive.bind(this);
    this.logout = this.logout.bind(this);
    this.setActive = this.setActive.bind(this);
  }
  
  handleClick(e) {  
    e.preventDefault();

    if (e.target.name === 'reset') {
      this.setState({ query: '' });
      this.props.setQuery('*');
    }
    
    this.props.runQuery(true);
  }
  
  handleKeyPress(e) {
    if (e.key === 'Enter') this.props.runQuery(true);
  }
  
  logout() {
    this.props.auth().signOut();
  }
  
  handleUpdate(e) {
    this.setState({ query: e.target.value });
    this.props.setQuery(e.target.value);
  }
  
  isActive(menu) {  
    return this.state.active === menu ? 'active' : '';
  }
  
  setActive(menu) {
    this.setState({ active: menu });
  }
  
  render() {
    const user = this.props.user;
  
    return (
      <Navbar bg="dark" variant="dark" fixed="top" expand="lg">
        <Navbar.Brand href="#products">AllThing</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          
            {user && (user.viewProducts || user.admin) &&
              <Nav.Item className={ `px-2 ${ this.isActive('products') }` }>
                <LinkContainer to="/products">
                  <Nav.Link onClick={ () => this.setActive('products') }>
                    Products
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            }

            {user && (user.viewListings || user.admin) &&
              <Nav.Item className={ `px-2 ${ this.isActive('listings') }` }>
                <LinkContainer to="/listings/all">
                  <Nav.Link onClick={ () => this.setActive('listings') }>
                    Listings
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            }

            {user && (user.viewOrders || user.admin) &&
              <Nav.Item className={ `px-2 ${ this.isActive('sales') }` }>
                <LinkContainer to="/sales">
                  <Nav.Link onClick={ () => this.setActive('sales') }>
                    Sales
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            }
            
            {user && (user.viewOrders || user.admin) &&
              <Nav.Item className={ `px-2 ${ this.isActive('purchases') }` }>
                <LinkContainer to="/purchases">
                  <Nav.Link onClick={ () => this.setActive('purchases') }>
                    Purchases
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            /*
              <NavDropdown 
                title="Orders" 
                id="collasible-nav-dropdown" 
                className={ `px-2 ${ this.isActive('purchases') }` }>
                  <LinkContainer to="/purchases">
                    <NavDropdown.Item 
                      onClick={ () => this.setActive('purchases') }>
                        Purchases
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/sales">
                    <NavDropdown.Item 
                      onClick={ () => this.setActive('purchases') }>
                        Sales
                    </NavDropdown.Item>
                  </LinkContainer>
              </NavDropdown>
            */
            }
              
            {
            /*
            user && (user.viewShipments || user.admin) &&
              <NavDropdown 
                title="Shipments" 
                id="collasible-nav-dropdown" 
                className={ `px-2 ${ this.isActive('shipments') }` }>
                  <LinkContainer to="/receipts">
                    <NavDropdown.Item 
                      onClick={ () => this.setActive('shipments') }>
                        Received
                    </NavDropdown.Item>
                  </LinkContainer>
              </NavDropdown>
            */
            }
  
            {
            /*            
              <Nav.Item className={ `px-2 ${ this.isActive('scan') }` }>
                <LinkContainer to="/scan">
                  <Nav.Link onClick={ () => this.setActive('scan') }>
                    Scan
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            */
            }
            
          </Nav>
          <Nav className="px-4">
            <Nav.Link onClick={this.logout}>Logout {this.props.user.name}</Nav.Link>
          </Nav>
          <Form inline>
            <FormControl 
              type="text" 
              placeholder="Search" 
              value={this.state.query}
              onChange={ this.handleUpdate } 
              onKeyPress={ this.handleKeyPress } 
              className="mr-sm-2" />
            <Button name="search" variant="outline-info" onClick={ this.handleClick }>Search</Button>
            &nbsp;
            <Button name="reset" variant="outline-info" onClick={ this.handleClick }>Reset</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>      
    );
  }
}

export { NavBarTop };
