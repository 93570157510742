import React from 'react';
import { RowComponent, formatAmount, getChannelIcon, getListing } from './AllThing';
import { Form, Image, Popover, Tooltip, OverlayTrigger, Container, Row, Col } from 'react-bootstrap';
import { FaMoneyBillWave, FaPencilAlt, FaPlusSquare, FaFileInvoiceDollar, FaTags, FaCopy, FaCartPlus } from 'react-icons/fa';
import Moment from 'react-moment';
import { StockWarehouse } from './StockWarehouse';

class ProductRow extends RowComponent {
  constructor(props) {
    super(props);    
    
    // Initialize state defaults
    this.state.listings = {};
    this.state.condition = props.defaultCondition || 'new';
    this.state.channel = props.defaultChannel || 'Amazon US';
    this.state.duplicates = [];
    this.state.orders = [];
    
    // onData callback for side-loading listing data
    this.onData(data => {    
      this.populateListings(data);
      this.checkDuplicate(data);
      this.checkOrders(data);
    });    
  }
  
  checkDuplicate(product) {
    this.search.simpleQuery('product', `model:"${product.model}"`)
      .then(results => this.setStateSafe({ duplicates: results }));
  }
  
  checkOrders(product) {
    const to = new Date();
    const from = new Date(to.getTime() - 1000 * 60 * 60 * 24 * 30);
    
    this.search.simpleQuery(
      'order_purchase_item',
      `
        (manifest.pid:"${ product.key }") 
        AND (NOT _exists_:manifest.committed)
        AND (NOT _exists_:history.returned) 
        AND (NOT _exists_:history.cancelled) 
        AND (history.paid:[${ from.toISOString() } TO ${ to.toISOString() }])
      `
    ).then(results => {
      this.setStateSafe({ orders: results });
    });
  }

  populateListings(product) {
    Object.keys(product.listing || {}).forEach(id => 
      getListing(id, snap => {
        this.addDataRef(snap.ref);

        this.setStateSafe({
          listings: {
            ...this.state.listings,
            [snap.key]: snap.val()
          }
        });
      })
    );
  }


/*  
    Promise.all(
      Object.keys(product.listing || { }).map(id =>
        new Promise((resolve, reject) => 
          getListing(id, snap => {
            this.addDataRef(snap.ref);
            resolve({ ...snap.val(), key: snap.key });
          })
        )
      )
    ).then(values => {
      const listings = { };
      
      values.map(listing => 
        listings[ listing.key ] = listing
      );
      
      this.setStateSafe({ listings });
    });
  
  }
*/
  
  render() {
    const { channel, condition, duplicates, listings, row, orders } = this.state;
    const { editListing, editProduct, editTags, handleCheck, isChecked, showImages } = this.props;
    const variant = row.variant || {};
    
    // Tabulate the ordered but yet received inventory
    const orderedQty = orders.reduce((acc, val) => {
      const manifest = val.manifest || [ ];
      let qty = 0;
      
      (val.manifest || [ ]).forEach(manifest => {
        if (manifest.type == "order") {
          qty += manifest.quantity;
        }
        
        if (manifest.condition === 'order'
          || manifest.condition === 'parts'
          || manifest.condition === 'return') {
            qty -= manifest.quantity;
        }
      });
      
      return acc + qty
    }, 0);
    
    return (
      <tr className="product-row">
        <td className="align-middle">
          <Form.Check
            checked={isChecked(row.key)} 
            type="checkbox" 
            onChange={() => handleCheck(row.key)} />
        </td>
        <td>
          <div className="thumbnail">
            <Image 
              fluid 
              src={row.image && row.image[0]} 
              onClick={() => showImages(row.image)} />
          </div>
        </td>
        <td className="product-details">
          <Container fluid>
            <Row>
              <Col>
                <Duplicate 
                  row={row}
                  duplicates={duplicates} />
                {row.vendor} {row.model}
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <FaFileInvoiceDollar className="price-icon text-success"/>
                <span className="price text-success">
                  <Pricing listings={listings} />
                </span>     
              </Col>
              <Col xs={7}>
                <StockWarehouse 
                  listings={ listings }
                  product={ row }
                  stock={ row.stock }
                  editListing={ editListing }
                  editStock={ () => this.props.editStock(row) } />     
                <NewChannel
                  product={row}
                  variant={variant[condition] || {}}
                  listings={listings}
                  editListing={editListing} />            
              </Col>
            </Row>
            <Row>
              <Col className="text-truncate">
                <FaTags className="product-tags" onClick={e => editTags(row)} />
                <small className="text-muted">{row.title}</small>
              </Col>
            </Row>
          </Container>
        </td>
        <td>
          {!!(orderedQty > 0) &&
            <span><FaCartPlus /> { orderedQty }x</span>
          }
        </td>
        <td className="product-analytics">
          <SalesAnalytics 
            product={row} />
        </td>
        <td>
          {row.lastSaleDate && 
            <Moment fromNow utc>{row.lastSaleDate}</Moment>
          }
        </td>
        <td>
          {row.stockOutDate && 
            <Moment fromNow utc>{row.stockOutDate}</Moment>
          }
        </td>
        <td>
          {row.created && 
            <Moment fromNow utc>{row.created}</Moment>
          }
        </td>
        <td className="text-right edit-controls">
          <FaPencilAlt onClick={e => editProduct(row)} />
        </td>
      </tr>
    );
  }
}

const Channels = (props) => {
  const { editListing, listings, product } = props;
  const markup = Object.values(listings).map(listing => (
    <OverlayTrigger 
      key={listing.key} 
      placement="top"
      overlay={ <Tooltip>
        <strong>Price:</strong> {parseInt(listing.price, 10)}</Tooltip> 
      }>
        {
          getChannelIcon(
            listing.channel, 
            listing.key,
            e => editListing(listing, product, listings))
        }
    </OverlayTrigger>
  ));
    
  return <span>{markup}</span>;
}

const NewChannel = props => {
  const { product, listings, editListing } = props;

  return (
    <FaPlusSquare 
      className="channel-icon add-channel align-text-top"
      onClick={e => editListing({}, product, listings)} />
  );
}

const Duplicate = props => {
  const { duplicates, row } = props;
  
  const overlay = (
    <Popover 
      title={`Possible Duplicates for ${row.model}`}
      id="manifest-item-popover">
        <ul>
          {duplicates.map(product =>
            <li key={product.key}>
              {product.vendor} 
              {product.model} 
              ({product.stitch && product.stitch.vid})
            </li>
          )}
        </ul>
    </Popover>        
  );
  
  const filteredDuplicates = duplicates.map(product => {
    if (product.key !== row.key 
      && product.model.toUpperCase() === row.model.toUpperCase() 
      && !product.archived) {
        return product;
    } else {
      return null;
    }  
  }).filter(Boolean);
  
  if (!filteredDuplicates.length) {
    return ( <span></span> );
  } 
  
  return (
    <OverlayTrigger 
      rootClose
      trigger="click" 
      placement="bottom"
      overlay={overlay}>
        <FaCopy className="duplicate text-warning" />
    </OverlayTrigger>
  );
}

const Pricing = (props) => {
  const excludeChannels = [ 'Amazon MX', 'Amazon CA' ];
  const { listings } = props;
  const pricing = [];

  Object.keys(listings || {}).map(listingId => {
    const listing = listings[listingId];
    const price = parseInt(listing.price || listing.channelPrice, 10);

    if (price && !excludeChannels.includes(listing.channel)) {
      pricing.push(price);
    }
    
    return null;
  });
  
  let uniquePricing = [ ...new Set(pricing) ];
  const maxPrice = '$' + Math.max(...uniquePricing).toFixed(2);
  const minPrice = '$' + Math.min(...uniquePricing).toFixed(2);
  
  if (!uniquePricing.length) {
    return 'None';
  } else if (uniquePricing.length === 1) {
    return maxPrice;
  } else {
    return `${minPrice} - ${maxPrice}`;
  }
}

const SalesAnalytics = (props) => {
  const { product } = props;
  
  if (product.analytics && product.analytics.sale) {
    const sold = product.analytics.sale;
    const period = (sold[365] && '365') || (sold.all && 'all');
    
    if (period) {
      const revenue = formatAmount(sold[period].sales);
      const units = sold[period].sold;
      const price = formatAmount(sold[period].sales / units)
      const old = period === '365' ? '' : 'text-muted';
    
      return (
        <Container fluid>
          <Row>
            <Col>
              <span className={old}>
                <FaMoneyBillWave className={`sales-icon sales-${period}`} />
                ${revenue}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <small className="text-muted">
                {units} sold | ${price} ea.
              </small>
            </Col>
          </Row>
        </Container>  
      );
    }      
  }
  
  return null;
}

export { ProductRow };
